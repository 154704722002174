import React from "react";
import { Form, Button, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "./components/FormInput";
import FormUpload from "./components/FormUpload";
import ModalComp from "components/Common/ModalComp";
import FormSelect from "./components/FormSelect";
import useUpload from "./hooks/useUpload";
import {
  deletePayloadChange,
  payloadFormCleanup,
  postPromo,
  updatePromo,
  updatePromoCleanup,
} from "store/actions";
import SweetAlert from "components/Common/SweetAlert";

const ModalForm = ({
  isOpen = false,
  toggle = () => null,
  type = "",
  setShowModal,
  setShowConfirm,
}) => {
  const dispatch = useDispatch();
  const { url: bannerUrl, doUpload, saveFileTemp, tempFile } = useUpload();
  const {
    title,
    categoryId,
    status,
    isHomepage,
    order,
    url,
    id = null,
  } = useSelector((s) => s.Promo);
  const { data } = useSelector((s) => s.MasterParams);
  const {
    data: dataPost,
    success: successPost,
    loading: loadingPost,
  } = useSelector((s) => s.PostPromo);
  const {
    data: dataUpdate,
    success: successUpdate,
    loading: loadingUpdate,
  } = useSelector((s) => s.UpdatePromo);
  const [showAlert, setShowAlert] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  React.useEffect(() => {
    if (successPost) onClose();
  }, [successPost]);

  React.useEffect(() => {
    if (successUpdate) onClose();
  }, [successUpdate]);

  const onSubmit = (e) => {
    e.preventDefault();

    const tempPayload = {};
    const formData = new FormData(e.currentTarget);

    formData.forEach((value, property) => {
      tempPayload[property] = value;
    });

    setPayload(tempPayload);

    if (tempFile) {
      const file = new FormData();
      file.append("image", tempFile);

      doUpload(file, {}, "banner");

      setPayload((prev) => ({ ...prev, url: bannerUrl }));
    } else {
      setPayload((prev) => ({ ...prev, url: url }));
    }

    setShowAlert(true);
  };

  const onConfirm = () => {
    if (type === "add") {
      setShowAlert(false);
      dispatch(postPromo(payload));
    } else {
      const idUpdate = id;

      setShowAlert(false);
      dispatch(deletePayloadChange("id"));
      dispatch(updatePromo(idUpdate, payload));
    }
    saveFileTemp(null);
  };

  const argValue = React.useCallback(
    (value) => ({ value }),
    [title, categoryId, status, isHomepage, order, url]
  );

  const onClose = () => {
    saveFileTemp(null);
    dispatch(payloadFormCleanup());
    dispatch(updatePromoCleanup());
    setShowModal(false);
    setShowConfirm(false);
  };

  const onCancel = () => setShowAlert(false);

  return (
    <>
      <ModalComp
        isOpen={isOpen}
        size="lg"
        toggle={onClose}
        title={type === "add" ? "Create Promo" : "Edit Promo"}
      >
        <Form onSubmit={onSubmit} method="post" encType="multipart/form-data">
          <ModalBody>
            <FormInput
              message={dataPost?.title || dataUpdate?.title}
              name="title"
              type="text"
              label="Nama Promo"
              placeholder="Nama promo"
              {...(type === "add" ? {} : argValue(title))}
            />
            <FormSelect
              message={dataPost?.categoryId || dataUpdate?.categoryId}
              label="Produk"
              placeholder="Pilih produk"
              options={data.category}
              name="categoryId"
              {...(type === "add" ? {} : argValue(categoryId))}
            />
            <FormInput
              name="status"
              type="select"
              label="Status"
              value={`${status}`}
            >
              <option value={1}>Aktif</option>
              <option value={0}>Nonaktif</option>
            </FormInput>
            <FormInput
              name="isHomepage"
              type="select"
              label="Is Homepage ?"
              value={`${isHomepage}`}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </FormInput>
            <FormInput
              message={dataPost?.order || dataUpdate?.order}
              name="order"
              type="number"
              label="Nomor urut"
              placeholder="Nomor urut"
              {...(type === "add" ? {} : argValue(order))}
            />
            <FormUpload
              message={dataPost?.url || dataUpdate?.url}
              name="url"
              getValue={(v) => saveFileTemp(v)}
              value={url}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="w-25" color="danger" outline onClick={onClose}>
              Batal
            </Button>
            <Button
              className="w-25"
              color="warning"
              type="submit"
              onSubmit={onSubmit}
            >
              Simpan
            </Button>
          </ModalFooter>
        </Form>
      </ModalComp>
      <SweetAlert
        type="confirm"
        title="Ubah data promo?"
        message="Perubahan akan disimpan setelah dikonfirmasi"
        show={showAlert}
        submitLabel="Konfirmasi"
        cancelLabel="Batal"
        onSubmit={onConfirm}
        onCancel={onCancel}
        loading={loadingPost || loadingUpdate}
      />
    </>
  );
};

export default ModalForm;
