import React from "react";
import {
  Row,
  Col,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { handleChange } from "store/actions";

const Filter = ({
  onExport = (type = "") => {},
  loadingExport = false,
  searchKey = "q",
}) => {
  const dispatch = useDispatch();

  const onSearch = React.useRef(
    debounce((e) => {
      dispatch(handleChange(searchKey, e.target.value));
    }, 500)
  ).current;

  return (
    <Row className="justify-content-between">
      <Col xs="12" md="auto">
        <Input
          placeholder="Search..."
          className="mb-xs-3 mb-md-0 rounded-pill ps-3"
          onChange={onSearch}
        />
      </Col>
      <Col xs="12" md="auto">
        <UncontrolledDropdown>
          <DropdownToggle color="warning" disabled={loadingExport}>
            {loadingExport ? (
              <Row className="align-items-center">
                <Col xs="auto">Loading</Col>
                <Col xs="auto">
                  <Spinner size="sm" />
                </Col>
              </Row>
            ) : (
              "Export Data"
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onExport("xls")}>XLSX</DropdownItem>
            <DropdownItem onClick={() => onExport("csv")}>CSV</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row>
  );
};

export default Filter;
