import React from "react";
import { Row, Col, Label, Card, Form } from "reactstrap";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeKios, uploadImage } from "store/actions";
import { formatBytes } from "helper";

const KiosDocument = () => {
  const [selectedFile, setSelectedFile] = React.useState({});
  const dispatch = useDispatch();

  const { ktp, logo } = useSelector((s) => s.Upload);
  const { ktp: ktpPreview, logo: logoPreview } = useSelector((s) => s.FormKios);

  React.useEffect(() => {
    if (ktp) dispatch(handleChangeKios("ktp", ktp));
    if (logo) dispatch(handleChangeKios("logo", logo));
  }, [ktp, logo]);

  const uploadKtp = (files) => {
    const formData = new FormData();

    formData.append("image", files[0]);

    dispatch(uploadImage(formData, {}, "ktp"));

    setSelectedFile((prev) => ({
      ...prev,
      ktp: { filename: files[0].name, size: formatBytes(files[0].size) },
    }));
  };

  const uploadLogo = (files) => {
    const formData = new FormData();

    formData.append("image", files[0]);

    dispatch(uploadImage(formData, {}, "logo"));

    setSelectedFile((prev) => ({
      ...prev,
      logo: { filename: files[0].name, size: formatBytes(files[0].size) },
    }));
  };

  return (
    <Form encType="multipart/form-data">
      <Row>
        <Col>
          <Label>
            File KTP max size <strong>500 Kb</strong> and the extension are{" "}
            <strong>JPG, JPEG</strong> and <strong>PNG</strong>
          </Label>
          <Dropzone
            onDrop={(acceptedFiles) => {
              uploadKtp(acceptedFiles);
            }}
            multiple={false}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h6>Tarik file kesini atau klik untuk memulai upload</h6>
                </div>
              </div>
            )}
          </Dropzone>
          {ktpPreview && (
            <div className="dropzone-previews mt-3" id="file-previews">
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="thumbnail-preview avatar-md rounded bg-light"
                        src={ktpPreview}
                      />
                    </Col>
                    <Col>
                      <Label to="#" className="text-muted font-weight-bold">
                        {selectedFile?.ktp?.filename || "KTP"}
                      </Label>
                      <p className="mb-0">
                        <strong>{selectedFile?.ktp?.size}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
        </Col>
        <Col>
          <Label>
            File Logo max size <strong>500 Kb</strong> and the extension are{" "}
            <strong>JPG, JPEG</strong> and <strong>PNG</strong>
          </Label>
          <Dropzone
            onDrop={(acceptedFiles) => {
              uploadLogo(acceptedFiles);
            }}
            multiple={false}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h6>Tarik file kesini atau klik untuk memulai upload</h6>
                </div>
              </div>
            )}
          </Dropzone>
          {logoPreview && (
            <div className="dropzone-previews mt-3" id="file-previews">
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="thumbnail-preview avatar-md rounded bg-light"
                        src={logoPreview}
                      />
                    </Col>
                    <Col>
                      <Label to="#" className="text-muted font-weight-bold">
                        {selectedFile?.logo?.filename || "Logo"}
                      </Label>
                      <p className="mb-0">
                        <strong>{selectedFile?.logo?.size}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default KiosDocument;
