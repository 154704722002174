import { encodeQueryData } from "helper";

const { get } = require("./api_helper");
const {
  FETCH_BALANCE,
  FETCH_SUMMARY,
  FETCH_TOP_SELLING,
  FETCH_TRANSACTION_ANALYTICS,
  FETCH_LATEST_TOPUP,
} = require("./constant");

const fetchBalance = () => get(`${FETCH_BALANCE}`);
const fetchSummary = () => get(`${FETCH_SUMMARY}`);
const fetchTopSelling = () => get(`${FETCH_TOP_SELLING}`);
const fetchTransactionAnalytics = () => get(`${FETCH_TRANSACTION_ANALYTICS}`);
const fetchLatestTopup = (params) =>
  get(
    `${FETCH_LATEST_TOPUP}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

export {
  fetchBalance,
  fetchSummary,
  fetchTopSelling,
  fetchTransactionAnalytics,
  fetchLatestTopup,
};
