import React from "react";
import { useDispatch } from "react-redux";
import { payloadChange } from "store/actions";

const useToggleModal = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [type, setType] = React.useState("add");
  const [showConfirm, setShowConfirm] = React.useState(false);

  const toggle = (data, type) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        dispatch(payloadChange(key, data[key]));
      });
    }

    setType(type);
    setShowModal(!showModal);
  };

  const toggleConfirm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        dispatch(payloadChange(key, data[key]));
      });
    }

    setShowConfirm(!showConfirm);
  };

  return {
    showModal,
    type,
    toggle,
    showConfirm,
    toggleConfirm,
    setShowConfirm,
    setShowModal,
  };
};

export default useToggleModal;
