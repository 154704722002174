export const FETCH_TOPUP = "/crm/topup";
export const UPDATE_STATUS_TOPUP = "/crm/topup/status";
export const POST_LOGIN = "/crm/auth/login";
export const EXPORT_EXCEL_TOPUP = "/crm/topup/export";
export const FETCH_TOPUP_HISTORY = "/crm/topup/history";
export const FETCH_KIOS = "/crm/kios";
export const FETCH_BANK = "/master/banks";
export const FETCH_PROVINCES = "/master/provinces";
export const FETCH_REGENCIES = "/master/regencies";
export const FETCH_DISTRICTS = "/master/districts";
export const UPLOAD_IMAGE = "/crm/upload";
export const ADD_KIOS = "/crm/kios";
export const UPDATE_STATUS_KIOS = "/crm/kios/status";
export const FETCH_DAFTAR_HARGA = "/crm/pricelist";
export const UPDATE_HARGA_JUAL = "/crm/pricelist/selling-price";
export const UPDATE_STATUS_HARGA_JUAL = "/crm/pricelist/status-product";
export const EDIT_KIOS = "/crm/kios";
export const FETCH_TRANSACTION = "/crm/transaction";
export const EXPORT_EXCEL_TRANSACTION = "/crm/transaction/export";
export const FETCH_BALANCE = "/crm/dashboard/balance";
export const FETCH_SUMMARY = "/crm/dashboard/summary";
export const FETCH_TOP_SELLING = "/crm/dashboard/top-selling-product";
export const FETCH_TRANSACTION_ANALYTICS =
  "/crm/dashboard/transaction-analytics";
export const FETCH_LATEST_TOPUP = "/crm/dashboard/latest-topup-balance";
export const FETCH_PRODUCT_LIST = "/crm/product";
export const FETCH_CATEGORY_LIST = "/crm/product/categorylist";
export const UPDATE_PRODUCT_LIST = "/crm/product/editproduct";
export const UPDATE_STATUS_PRODUCT_LIST = "/crm/product/status";
export const FETCH_PROMO_URL = "/crm/promotion-banner";
export const UPDATE_VERIFICATION_KIOS = "/kios/verification-status";
export const FETCH_ACADEMY_LIST = "/crm/academy-banner";
