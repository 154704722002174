import { priceDecimal } from "helper";
import moment from "moment";
import React from "react";
import { Card, CardBody, Badge, Row, Col } from "reactstrap";
import { EmptyWallet } from "iconsax-react";

const statusBadge = {
  0: {
    color: "warning",
    label: "Pending",
    icon: "",
  },
  1: {
    color: "danger",
    label: "Gagal",
    icon: "",
  },
  2: {
    color: "success",
    label: "Sukses",
    icon: "",
  },
};

const CardHistory = ({
  kiosName = "",
  createdAt = "",
  amount = 0,
  status = 0,
}) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div className="icon-wrapper">
              <EmptyWallet variant="Bulk" color="#00A1E1" size={15} />
            </div>
          </Col>
          <Col>
            <h5>Topup Saldo</h5>
            <p>Permintaan topup saldo kepada kios {kiosName}</p>
            <span>{moment(createdAt).format("d MMM Y, hh:mm")}</span>
          </Col>
          <Col xs="auto">
            <p>Rp {priceDecimal(amount)}</p>
            <Badge color={statusBadge[status].color} className="status">
              {statusBadge[status].label}
            </Badge>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardHistory;
