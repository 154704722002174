import React from "react";
import { priceDecimal } from "helper";
import moment from "moment";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";

const status = {
  0: <Badge color="warning">Pending</Badge>,
  1: <Badge color="danger">Gagal</Badge>,
  2: <Badge color="success">Berhasil</Badge>,
};

const columns = ({ onShowConfirm = () => {}, onShowDetail = () => {} }) => {
  const { page, perPage } = useSelector((state) => state.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (__, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "Request ID",
      // selector: "id",
      minWidth: "130px",
      cell: (row) => <span className="obj-el">#{row.id}</span>,
    },
    {
      name: "Kios Name",
      // selector: "firstName",
      minWidth: "120px",
      cell: (row) => (
        <span className="obj-el">{`${row.seller.firstName} ${row.seller.lastName}`}</span>
      ),
    },
    {
      name: "Tanggal Request",
      // selector: "createdAt",
      minWidth: "200px",
      cell: (row) => (
        <span className="obj-el">
          {moment(row.createdAt).format("D MMM Y, HH:mm")}
        </span>
      ),
    },
    {
      name: "Nomor Telepon",
      // selector: "phone",
      minWidth: "150px",
      cell: (row) => <span className="obj-el">{row.seller?.phone}</span>,
    },
    {
      name: "Jumlah Topup",
      // selector: "amount",
      minWidth: "200px",
      cell: (row) => (
        <span className="obj-el">Rp {priceDecimal(row.amount)}</span>
      ),
    },
    {
      name: "Bank",
      // selector: "bankName",
      minWidth: "150px",
      cell: (row) => <span className="obj-el">{row.seller?.bankName}</span>,
    },
    {
      name: "No. Rekening",
      // selector: "accountNumber",
      minWidth: "130px",
      cell: (row) => (
        <span className="obj-el">{row.seller?.accountNumber}</span>
      ),
    },
    {
      name: "Status Pembayaran",
      // selector: "status",
      minWidth: "180px",
      cell: (row) => <div className="obj-el">{status[row.status]}</div>,
    },
    {
      name: "Action",
      sortable: false,
      minWidth: 43,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a">
            <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => onShowConfirm(2, row.id, "approve")}>
              Konfirmasi Pembayaran
            </DropdownItem>
            <DropdownItem onClick={() => onShowDetail(row.seller)}>
              Riwayat Topup Saldo
            </DropdownItem>
            <DropdownItem>Detail Kios</DropdownItem>
            <DropdownItem onClick={() => onShowConfirm(1, row.id, "reject")}>
              Batalkan Request
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
};

export default columns;
