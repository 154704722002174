import {
  FETCH_TOPUP,
  FETCH_TOPUP_FAILED,
  FETCH_TOPUP_SUCCESS,
  UPDATE_STATUS_CLEAN_UP,
  UPDATE_STATUS_TOPUP,
  UPDATE_STATUS_TOPUP_FAILED,
  UPDATE_STATUS_TOPUP_SUCCESS,
  EXPORT_TOPUP_EXCEL,
  EXPORT_TOPUP_EXCEL_FAILED,
  EXPORT_TOPUP_EXCEL_SUCCESS,
  EXPORT_TOPUP_EXCEL_CLEAN_UP,
  FETCH_TOPUP_HISTORY_SUCCESS,
  FETCH_TOPUP_HISTORY,
  FETCH_TOPUP_HISTORY_FAILED,
} from "./actionTypes";

const initState = {
  loading: false,
  data: [],
  total: 0,
  message: null,
};

const initStatePost = {
  loading: false,
  data: null,
  message: null,
  messageTitle: null,
};

const initStateHistory = {
  loading: false,
  data: [],
  total: 0,
  message: null,
};

const TopUp = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TOPUP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOPUP_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        loading: false,
      };
    case FETCH_TOPUP_FAILED:
      return {
        ...state,
        data: [],
        message: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};

export const UpdateStatusTopup = (state = initStatePost, action) => {
  switch (action.type) {
    case UPDATE_STATUS_TOPUP:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_STATUS_TOPUP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        data: action.payload.data || {},
        loading: false,
      };
    case UPDATE_STATUS_TOPUP_FAILED:
      return {
        ...state,
        data: null,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
      };
    case UPDATE_STATUS_CLEAN_UP:
      return initStatePost;
    default:
      return state;
  }
};

export const ExportExcel = (state = initStatePost, action) => {
  switch (action.type) {
    case EXPORT_TOPUP_EXCEL:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_TOPUP_EXCEL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
      };
    case EXPORT_TOPUP_EXCEL_FAILED:
      return {
        ...state,
        data: null,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
      };
    case EXPORT_TOPUP_EXCEL_CLEAN_UP:
      return initStatePost;
    default:
      return state;
  }
};

export const HistoryTopup = (state = initStateHistory, action) => {
  switch (action.type) {
    case FETCH_TOPUP_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOPUP_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        total: action.payload.data.total,
      };
    case FETCH_TOPUP_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        total: 0,
      };
    default:
      return state;
  }
};

export default TopUp;
