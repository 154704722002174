import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { uploadImage } from "helpers/master_helper";
import { postAcademyBanner, putAcademyBanner } from "helpers/academy_helper";
import "./style.scss";
import { toast } from "react-toastify";
import CustomToast from "components/Common/CustomToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initPayload = {
  title: "",
  detail: "",
  order: 1,
  status: 0,
};

const labelFormMapping = {
  title: "Judul",
  detail: "Detail",
  order: "Nomor Urut",
  status: "Status",
};

const ModalForm = ({ isOpen, onClose, onFetch, dataEdit }) => {
  const [payload, setPayload] = useState(initPayload);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && dataEdit) {
      setPayload(dataEdit);
      setFile(dataEdit.url);
    }
  }, [isOpen, dataEdit]);

  const handleChange = useCallback(({ target: { value, name } }) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleFile = useCallback((files) => {
    setFile(files[0]);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        const tempErrors = {};
        const formData = new FormData();

        if (file === null) {
          tempErrors.file = `Banner harus diisi`;
        }

        for (const key in payload) {
          if (payload[key] === "") {
            tempErrors[key] = `${labelFormMapping[key]} harus diisi`;

            setErrors(tempErrors);
            setLoading(false);
          }
        }

        if (Object.keys(tempErrors).length === 0) {
          let url = file;
          let response;

          if (typeof file !== "string") {
            formData.append("image", file);

            const { data } = await uploadImage(formData);
            url = data?.url;

            formData.delete("image");
          }

          if (dataEdit) {
            response = await putAcademyBanner({ ...payload, url }, payload?.id);
          } else {
            response = await postAcademyBanner({ ...payload, url });
          }

          toast(
            <CustomToast
              title={response?.messageTitle}
              desc={response?.message}
              type={"success"}
            />
          );
          handleReset();
          setLoading(false);
          onFetch();
        }
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [payload.title, payload.detail, file]
  );

  const handleReset = useCallback(() => {
    setPayload(initPayload);
    setErrors({});
    setFile(null);
    setLoading(false);
    onClose();
  }, []);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <Modal isOpen={isOpen} toggle={handleReset} id="form-input" size="lg">
      <ModalHeader toggle={handleReset}>Create Materi</ModalHeader>
      <Form method="POST" onSubmit={handleSubmit} onReset={handleReset}>
        <ModalBody>
          <FormGroup>
            <Label>Judul Materi</Label>
            <Input
              type="text"
              placeholder="Judul..."
              value={payload.title}
              name="title"
              onChange={handleChange}
            />
            {errors?.title?.length > 0 && (
              <small className="text-danger">{errors?.title}</small>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Detail Materi</Label>
            <CKEditor
              editor={ClassicEditor}
              data={payload.detail}
              onChange={(event, editor) => {
                const data = editor.getData();

                handleChange({ target: { name: "detail", value: data } });
              }}
              config={{
                ckfinder: {
                  uploadUrl:
                    "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json",
                },
              }}
            />
            {errors?.detail?.length > 0 && (
              <small className="text-danger">{errors?.detail}</small>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input
              type="select"
              value={payload.status}
              name="status"
              onChange={handleChange}
            >
              <option value={1}>Aktif</option>
              <option value={0}>Tidak Aktif</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Nomor Urut</Label>
            <Input
              type="number"
              placeholder="Nomor Urut"
              value={payload.order}
              name="order"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Upload Banner</Label>
            <Dropzone onDrop={handleFile} multiple={false} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h6>Tarik file kesini atau klik untuk memulai upload</h6>
                  </div>
                </div>
              )}
            </Dropzone>
            {file && (
              <div className="dropzone-previews mt-3" id="file-previews">
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="thumbnail-preview avatar-md rounded bg-light"
                          src={
                            typeof file === "string"
                              ? file
                              : URL.createObjectURL(file)
                          }
                        />
                      </Col>
                      <Col>
                        <Label to="#" className="text-muted font-weight-bold">
                          {typeof file === "string"
                            ? file
                                .split("/")
                                [file.split("/").length - 1]?.split("?")[0] ??
                              ""
                            : file?.name}
                        </Label>
                        <p className="mb-0">
                          {typeof file === "object" && (
                            <strong>{formatFileSize(file?.size)}</strong>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            )}
            {errors?.file?.length > 0 && (
              <small className="text-danger">{errors?.file}</small>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="w-25"
            type="reset"
            color="danger"
            outline
            disabled={loading}
          >
            Batal
          </Button>
          <Button
            className="w-25"
            type="submit"
            color="warning"
            disabled={loading}
          >
            {loading ? (
              <>
                Loading <Spinner size="sm" />
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalForm;
