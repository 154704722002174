import React from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanks, fetchProvinces } from "store/master/actions";
import KiosDetail from "components/FormKios/KiosDetail";
import KiosAddress from "components/FormKios/KiosAddress";
import KiosBank from "components/FormKios/KiosBank";
import KiosDocument from "components/FormKios/KiosDocument";
import {
  addKios,
  addKiosCleanup,
  editKios,
  handleChangeCleanup,
  updateKios,
  updateKiosCleanup,
} from "store/actions";
import { toast, ToastContainer } from "react-toastify";
import CustomToast from "components/Common/CustomToast";
import { useHistory, useParams } from "react-router-dom";

const FormKios = () => {
  document.title = "Form Kios | Pulsadong";

  const [activeTab, setactiveTab] = React.useState(1);
  const [passedSteps, setPassedSteps] = React.useState([]);
  const { push, replace } = useHistory();

  const dispatch = useDispatch();
  const { data: dataProvinces } = useSelector((s) => s.Provinces);
  const { data: dataBanks } = useSelector((s) => s.Bank);
  const formDataKios = useSelector((s) => s.FormKios);
  const {
    data: dataAddKios,
    message,
    messageTitle,
    loading,
    success,
  } = useSelector((s) => s.AddKios);
  const {
    data: dataUpdateKios,
    message: messageUpdateKios,
    messageTitle: messageTitleUpdateKios,
    loading: loadingUpdateKios,
    success: successUpdateKios,
  } = useSelector((s) => s.UpdateKios);
  const { id } = useParams();

  React.useEffect(() => {
    return () => {
      dispatch(addKiosCleanup());
      dispatch(handleChangeCleanup());
      dispatch(updateKiosCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (id) {
      dispatch(editKios(id));
    }
  }, [id]);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (dataProvinces.length == 0) {
      if (activeTab == 2) dispatch(fetchProvinces({}, { signal }));
      else controller.abort();
    }

    if (dataBanks.length == 0) {
      if (activeTab == 3) dispatch(fetchBanks({}));
      else controller.abort();
    }

    return () => controller.abort();
  }, [activeTab]);

  React.useEffect(() => {
    if (dataAddKios) {
      if (success) {
        setTimeout(() => {
          push("/kios");
        }, 3000);
      }

      toast(
        <CustomToast
          title={messageTitle}
          desc={message}
          type={success ? "success" : "error"}
        />
      );
    }
  }, [dataAddKios]);

  React.useEffect(() => {
    if (dataUpdateKios) {
      if (successUpdateKios) {
        setTimeout(() => {
          push("/kios");
        }, 3000);
      }

      toast(
        <CustomToast
          title={messageTitleUpdateKios}
          desc={messageUpdateKios}
          type={successUpdateKios ? "success" : "error"}
        />
      );
    }
  }, [dataUpdateKios]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const onSubmit = () => {
    if (id) {
      let newFormDataKios = formDataKios;

      if (newFormDataKios.pin.toString().length == 0) {
        delete newFormDataKios.pin;
      }

      dispatch(updateKios(newFormDataKios, id));

      return;
    }

    dispatch(addKios(formDataKios));
  };

  return (
    <React.Fragment>
      <div className="page-content" id="form-kios">
        <Container fluid>
          <Breadcrumbs title="Kios" breadcrumbItem="Form Kios" />
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Basic Wizard</h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1);
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span>
                        Kios Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2);
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span>Kios Address
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3);
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">3.</span>Bank Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4);
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">4.</span>Foto / Logo Kios
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix mt-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <KiosDetail />
                    </TabPane>
                    <TabPane tabId={2}>
                      <KiosAddress />
                    </TabPane>
                    <TabPane tabId={3}>
                      <KiosBank />
                    </TabPane>
                    <TabPane tabId={4}>
                      <KiosDocument />
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li>
                      <Button
                        color="warning"
                        outline
                        onClick={() => {
                          toggleTab(activeTab - 1);
                        }}
                        className={activeTab === 1 ? "disabled" : ""}
                      >
                        Previous
                      </Button>
                    </li>
                    <li>
                      <Button
                        color="warning"
                        onClick={() => {
                          if (activeTab === 4) onSubmit();
                          else toggleTab(activeTab + 1);
                        }}
                      >
                        <Row className="align-items-center">
                          <Col
                            xs="auto"
                            className={
                              loading || loadingUpdateKios ? "pe-0" : ""
                            }
                          >
                            {loading || loadingUpdateKios
                              ? "Loading"
                              : activeTab === 4
                              ? "Submit"
                              : "Next"}
                          </Col>
                          {loading ||
                            (loadingUpdateKios && (
                              <Col xs="auto">
                                <Spinner size="sm" />
                              </Col>
                            ))}
                        </Row>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        progressClassName="progressbar"
        autoClose={2000}
      />
    </React.Fragment>
  );
};

export default FormKios;
