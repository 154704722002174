import {
  fetchCategoryList,
  fetchProductList,
  updateProductList,
  updateStatusProductList,
} from "helpers/product_list_herper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchCategoryListFailed,
  fetchCategoryListSuccess,
  fetchProductListFailed,
  fetchProductListSuccess,
  updateProductListFailed,
  updateProductListSuccess,
  updateStatusProductListFailed,
  updateStatusProductListSuccess,
} from "./actions";
import {
  FETCH_CATEGORY_LIST,
  FETCH_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST,
  UPDATE_STATUS_PRODUCT_LIST,
} from "./actionTypes";

function* getProductListData({ payload: { params } }) {
  try {
    const response = yield call(fetchProductList, params);

    yield put(fetchProductListSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchProductListFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchProductListFailed(error.response.data));
    }
  }
}

function* getCategoryListData() {
  try {
    const response = yield call(fetchCategoryList);

    yield put(fetchCategoryListSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchCategoryListFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchCategoryListFailed(error.response.data));
    }
  }
}

function* updateProductData({ payload: { data, id } }) {
  try {
    const response = yield call(updateProductList, data, id);

    yield put(updateProductListSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateProductListFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateProductListFailed(error.response.data));
    }
  }
}

function* updateStatusProductData({ payload: { data, id } }) {
  try {
    const response = yield call(updateStatusProductList, data, id);

    yield put(updateStatusProductListSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateStatusProductListFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateStatusProductListFailed(error.response.data));
    }
  }
}

function* DaftarProduk() {
  yield takeEvery(FETCH_PRODUCT_LIST, getProductListData);
  yield takeEvery(FETCH_CATEGORY_LIST, getCategoryListData);
  yield takeEvery(UPDATE_PRODUCT_LIST, updateProductData);
  yield takeEvery(UPDATE_STATUS_PRODUCT_LIST, updateStatusProductData);
}

export default DaftarProduk;
