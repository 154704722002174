import { call, put, takeEvery } from "redux-saga/effects";
import {
  EXPORT_TOPUP_EXCEL,
  FETCH_TOPUP,
  FETCH_TOPUP_HISTORY,
  UPDATE_STATUS_TOPUP,
} from "./actionTypes";
import {
  fetchTopupSuccess,
  fetchTopupFailed,
  updateStatusTopupSuccess,
  updateStatusTopupFailed,
  exportTopupSuccess,
  exportTopupFailed,
  topupHistorySuccess,
  topupHistoryFailed,
} from "./actions";
import {
  fetchTopup,
  updateStatusTopup,
  exportTopup,
  fetchTopupHistory,
} from "helpers/topup_helper";

function* getTopupData({ payload: { params } }) {
  try {
    const response = yield call(fetchTopup, params);

    yield put(fetchTopupSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchTopupFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchTopupFailed(error.response.data));
    }
  }
}

function* updateStatus({ payload: { data, id } }) {
  try {
    const response = yield call(updateStatusTopup, data, id);

    yield put(updateStatusTopupSuccess(response));
  } catch (error) {
    if (error.response?.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response?.status == 500) {
      yield put(updateStatusTopupFailed(error.response?.data));

      location.href = "/oops";
    } else {
      yield put(updateStatusTopupFailed(error.response?.data));
    }
  }
}

function* getExportData({ payload: { params } }) {
  try {
    const response = yield call(exportTopup, params);

    yield put(exportTopupSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(exportTopupFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(exportTopupFailed(error.response.data));
    }
  }
}

function* getTopupHistory({ payload: { params, id, config } }) {
  try {
    const response = yield call(fetchTopupHistory, params, id, config);

    yield put(topupHistorySuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(topupHistoryFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(topupHistoryFailed(error.response.data));
    }
  }
}

function* TopUpSaga() {
  yield takeEvery(FETCH_TOPUP, getTopupData);
  yield takeEvery(UPDATE_STATUS_TOPUP, updateStatus);
  yield takeEvery(EXPORT_TOPUP_EXCEL, getExportData);
  yield takeEvery(FETCH_TOPUP_HISTORY, getTopupHistory);
}

export default TopUpSaga;
