export const FETCH_KIOS = "FETCH_KIOS";
export const FETCH_KIOS_SUCCESS = "FETCH_KIOS_SUCCESS";
export const FETCH_KIOS_FAILED = "FETCH_KIOS_FAILED";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const ADD_KIOS = "ADD_KIOS";
export const ADD_KIOS_SUCCESS = "ADD_KIOS_SUCCESS";
export const ADD_KIOS_FAILED = "ADD_KIOS_FAILED";
export const ADD_KIOS_CLEANUP = "ADD_KIOS_CLEANUP";
export const HANDLE_CHANGE_CLEANUP = "HANDLE_CHANGE_CLEANUP";
export const UPDATE_STATUS_KIOS = "UPDATE_STATUS_KIOS";
export const UPDATE_STATUS_KIOS_SUCCESS = "UPDATE_STATUS_KIOS_SUCCESS";
export const UPDATE_STATUS_KIOS_FAILED = "UPDATE_STATUS_KIOS_FAILED";
export const UPDATE_STATUS_KIOS_CLEANUP = "UPDATE_STATUS_CLEANUP";
export const EDIT_KIOS = "EDIT_KIOS";
export const EDIT_KIOS_SUCCESS = "EDIT_KIOS_SUCCESS";
export const EDIT_KIOS_FAILED = "EDIT_KIOS_FAILED";
export const EDIT_KIOS_CLEANUP = "EDIT_KIOS_CLEANUP";
export const UPDATE_KIOS = "UPDATE_KIOS";
export const UPDATE_KIOS_SUCCESS = "UPDATE_KIOS_SUCCESS";
export const UPDATE_KIOS_FAILED = "UPDATE_KIOS_FAILED";
export const UPDATE_KIOS_CLEANUP = "UPDATE_KIOS_CLEANUP";
export const UPDATE_VERIFICATION = "UPDATE_VERIFICATION";
export const UPDATE_VERIFICATION_SUCCESS = "UPDATE_VERIFICATION_SUCCESS";
export const UPDATE_VERIFICATION_FAILED = "UPDATE_VERIFICATION_FAILED";
export const UPDATE_VERIFICATION_CLEANUP = "UPDATE_VERIFICATION_CLEANUP";
