import {
  FETCH_DAFTAR_HARGA,
  FETCH_DAFTAR_HARGA_FAILED,
  FETCH_DAFTAR_HARGA_SUCCESS,
  UPDATE_HARGA_JUAL,
  UPDATE_HARGA_JUAL_CLEANUP,
  UPDATE_HARGA_JUAL_FAILED,
  UPDATE_HARGA_JUAL_SUCCESS,
  UPDATE_STATUS_HARGA_JUAL,
  UPDATE_STATUS_HARGA_JUAL_CLEANUP,
  UPDATE_STATUS_HARGA_JUAL_FAILED,
  UPDATE_STATUS_HARGA_JUAL_SUCCESS,
} from "./actionTypes";

export const fetchDaftarHarga = (params) => ({
  type: FETCH_DAFTAR_HARGA,
  payload: { params },
});

export const fetchDaftarHargaSuccess = (data) => ({
  type: FETCH_DAFTAR_HARGA_SUCCESS,
  payload: data,
});

export const fetchDaftarHargaFailed = (error) => ({
  type: FETCH_DAFTAR_HARGA_FAILED,
  payload: error,
});

export const updateHargaJual = (data, id) => ({
  type: UPDATE_HARGA_JUAL,
  payload: { data, id },
});

export const updateHargaJualSuccess = (data) => ({
  type: UPDATE_HARGA_JUAL_SUCCESS,
  payload: data,
});

export const updateHargaJualFailed = (error) => ({
  type: UPDATE_HARGA_JUAL_FAILED,
  payload: error,
});

export const updateHargaJualCleanup = () => ({
  type: UPDATE_HARGA_JUAL_CLEANUP,
});

export const updateStatusHargaJual = (data, id) => ({
  type: UPDATE_STATUS_HARGA_JUAL,
  payload: { data, id },
});

export const updateStatusHargaJualSuccess = (data) => ({
  type: UPDATE_STATUS_HARGA_JUAL_SUCCESS,
  payload: data,
});

export const updateStatusHargaJualFailed = (error) => ({
  type: UPDATE_STATUS_HARGA_JUAL_FAILED,
  payload: error,
});

export const updateStatusHargaJualCleanup = () => ({
  type: UPDATE_STATUS_HARGA_JUAL_CLEANUP,
});
