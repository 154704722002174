import { UPDATE_VERIFICATION_KIOS } from "helpers/constant";
import {
  FETCH_KIOS,
  FETCH_KIOS_SUCCESS,
  FETCH_KIOS_FAILED,
  HANDLE_CHANGE,
  ADD_KIOS,
  ADD_KIOS_SUCCESS,
  ADD_KIOS_FAILED,
  ADD_KIOS_CLEANUP,
  HANDLE_CHANGE_CLEANUP,
  UPDATE_STATUS_KIOS,
  UPDATE_STATUS_KIOS_SUCCESS,
  UPDATE_STATUS_KIOS_FAILED,
  UPDATE_STATUS_KIOS_CLEANUP,
  EDIT_KIOS,
  EDIT_KIOS_SUCCESS,
  EDIT_KIOS_FAILED,
  EDIT_KIOS_CLEANUP,
  UPDATE_KIOS,
  UPDATE_KIOS_SUCCESS,
  UPDATE_KIOS_FAILED,
  UPDATE_KIOS_CLEANUP,
  UPDATE_VERIFICATION_SUCCESS,
  UPDATE_VERIFICATION_FAILED,
  UPDATE_VERIFICATION_CLEANUP,
} from "./actionTypes";

const initState = {
  loading: false,
  data: [],
  total: 0,
  message: null,
};

const formState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  pin: "",
  provinceId: "",
  regionId: "",
  districtId: "",
  postalCode: "",
  address: "",
  bank: "",
  bankBranch: "",
  accountNumber: "",
  accountName: "",
  ktp: "",
  logo: "",
};

const postState = {
  loading: false,
  data: null,
  message: null,
  messageTitle: null,
  success: null,
};

const editState = {
  loading: false,
  data: null,
};

const Kios = (state = initState, action) => {
  switch (action.type) {
    case FETCH_KIOS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_KIOS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        loading: false,
      };
    case FETCH_KIOS_FAILED:
      return {
        ...state,
        data: [],
        maessage: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};

export const FormKios = (state = formState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      const { name, value } = action.payload;

      return {
        ...state,
        [name]: value,
      };
    case EDIT_KIOS_SUCCESS:
      const data = action.payload.data;
      const newData = {
        ...data,
      };

      delete newData.user;

      return {
        ...state,
        loading: false,
        ...newData,
      };
    case EDIT_KIOS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
      };
    case HANDLE_CHANGE_CLEANUP:
      return formState;
    default:
      return state;
  }
};

export const AddKios = (state = postState, action) => {
  switch (action.type) {
    case ADD_KIOS:
      return {
        ...state,
        loading: true,
      };
    case ADD_KIOS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case ADD_KIOS_FAILED:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case ADD_KIOS_CLEANUP:
      return postState;
    default:
      return state;
  }
};

export const UpdateStatusKios = (state = postState, action) => {
  switch (action.type) {
    case UPDATE_STATUS_KIOS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_STATUS_KIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_STATUS_KIOS_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_STATUS_KIOS_CLEANUP:
      return postState;
    default:
      return state;
  }
};

export const EditKios = (state = editState, action) => {
  switch (action.type) {
    case EDIT_KIOS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_KIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case EDIT_KIOS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
      };
    case EDIT_KIOS_CLEANUP:
      return editState;
    default:
      return state;
  }
};

export const UpdateKios = (state = postState, action) => {
  switch (action.type) {
    case UPDATE_KIOS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_KIOS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case UPDATE_KIOS_FAILED:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case UPDATE_KIOS_CLEANUP:
      return postState;
    default:
      return state;
  }
};

export const UpdateVerification = (state = postState, action) => {
  switch (action.type) {
    case UPDATE_VERIFICATION_KIOS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case UPDATE_VERIFICATION_FAILED:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
        loading: false,
      };
    case UPDATE_VERIFICATION_CLEANUP:
      return postState;
    default:
      return state;
  }
};

export default Kios;
