export const FETCH_DAFTAR_HARGA = "FETCH_DAFTAR_HARGA";
export const FETCH_DAFTAR_HARGA_SUCCESS = "FETCH_DAFTAR_HARGA_SUCCESS";
export const FETCH_DAFTAR_HARGA_FAILED = "FETCH_DAFTAR_HARGA_FAILED";
export const UPDATE_HARGA_JUAL = "UPDATE_HARGA_JUAL";
export const UPDATE_HARGA_JUAL_SUCCESS = "UPDATE_HARGA_JUAL_SUCCESS";
export const UPDATE_HARGA_JUAL_FAILED = "UPDATE_HARGA_JUAL_FAILED";
export const UPDATE_HARGA_JUAL_CLEANUP = "UPDATE_HARGA_JUAL_CLEANUP";
export const UPDATE_STATUS_HARGA_JUAL = "UPDATE_STATUS_HARGA_JUAL";
export const UPDATE_STATUS_HARGA_JUAL_SUCCESS =
  "UPDATE_STATUS_HARGA_JUAL_SUCCESS";
export const UPDATE_STATUS_HARGA_JUAL_FAILED =
  "UPDATE_STATUS_HARGA_JUAL_FAILED";
export const UPDATE_STATUS_HARGA_JUAL_CLEANUP =
  "UPDATE_STATUS_HARGA_JUAL_CLEANUP";
