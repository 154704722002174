import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Table } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { fetchTopSelling } from "store/actions";
import { isNegative, priceDecimal } from "helper";

const getChartOptions = (index) => {
  var options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  };
  switch (index) {
    case 1:
      options["colors"][0] = "#556ee6";
      break;
    case 2:
      options["colors"][0] = "#34c38f";
      break;
    case 3:
      options["colors"][0] = "#f46a6a";
      break;
    default:
      break;
  }

  return options;
};

const TopSellingProduct = (props) => {
  const dispatch = useDispatch();

  const [seletedMonth, setSeletedMonth] = useState("jan");
  const { loading, data } = useSelector((state) => state.TopSelling);

  useEffect(() => {
    dispatch(fetchTopSelling());
  }, []);

  const onChangeMonth = (value) => {
    setSeletedMonth(value);
  };

  return (
    <React.Fragment>
      <Col xs={12} md={4}>
        <Card>
          <CardBody>
            <div className="clearfix">
              {/* <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={(e) => {
                      onChangeMonth(e.target.value);
                    }}
                  >
                    <option value="jan">Jan</option>
                    <option value="dec">Dec</option>
                    <option value="nov">Nov</option>
                    <option value="oct">Oct</option>
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div> */}
              <h4 className="card-title mb-4">Top Selling product</h4>
            </div>

            <div className="text-muted text-center">
              <p className="mb-2">Total Omset</p>
              <h4>Rp {priceDecimal(data.totalOmset.value)}</h4>
              <p className="mt-4 mb-0">
                <span
                  className={`badge ${
                    isNegative(data.totalOmset.percentage)
                      ? "badge-soft-danger"
                      : "badge-soft-success"
                  } font-size-11 me-2`}
                >
                  {" "}
                  {data.totalOmset.percentage}{" "}
                  <i
                    className={`mdi ${
                      isNegative(data.totalOmset.percentage)
                        ? "mdi-arrow-down"
                        : "mdi-arrow-up"
                    }`}
                  />{" "}
                </span>{" "}
                Dari Bulan Sebelumnya
              </p>
            </div>

            <div className="table-responsive mt-4">
              <Table className="table align-middle mb-0">
                <tbody>
                  {(data.topSellingProduct || []).map((item, key) => {
                    const options = getChartOptions(key + 1);
                    return (
                      <tr key={key}>
                        <td>
                          <h5 className="font-size-14 mb-1">
                            {item.categoryName}
                          </h5>
                        </td>

                        <td>
                          <div id="radialchart-1">
                            <ReactApexChart
                              options={options}
                              series={[item.totalSales]}
                              type="radialBar"
                              height={60}
                              width={60}
                              className="apex-charts"
                            />
                          </div>
                        </td>
                        <td>
                          <p className="text-muted mb-1">Sales</p>
                          <h5 className="mb-0">{item.totalSales} %</h5>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TopSellingProduct;
