import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { handleChange } from "store/actions";
import { Filter as FilterIcon } from "iconsax-react";

const Filter = ({ onOpenForm }) => {
  const dispatch = useDispatch();

  const onSearch = React.useRef(
    debounce((e) => {
      dispatch(handleChange("search", e.target.value));
    }, 500)
  ).current;

  return (
    <Row className="justify-content-between">
      <Col xs="12" md="auto" className="mb-3 mb-md-0">
        <Row>
          <Col xs="auto">
            <UncontrolledDropdown>
              <DropdownToggle
                color="warning"
                className="rounded-circle px-2 py-2"
                size="md"
              >
                <FilterIcon color="#FFFFFF" size={18} type="linear" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Aktif</DropdownItem>
                <DropdownItem>Tidak Aktif</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="12" md="auto">
            <Input
              placeholder="Search..."
              className="mb-xs-3 mb-md-0 rounded-pill ps-3"
              onChange={onSearch}
            />
          </Col>
        </Row>
      </Col>
      <Col xs="12" md="auto">
        <Button color="warning" onClick={onOpenForm}>
          Tambah Materi
        </Button>
      </Col>
    </Row>
  );
};

export default Filter;
