import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Form, Row, Col, Label, Input } from "reactstrap";
import {
  fetchDistricts,
  fetchRegencies,
  handleChangeKios,
} from "store/actions";

const KiosAddress = () => {
  const dispatch = useDispatch();
  const {
    data: dataProvinces,
    loading: loadingProvinces,
    message: messageProvinces,
    messageTitle: messageTitleProvinces,
  } = useSelector((s) => s.Provinces);

  const {
    data: dataRegencies,
    loading: loadingRegencies,
    message: messageRegencies,
    messageTitle: messageTitleRegencies,
  } = useSelector((s) => s.Regencies);

  const {
    data: dataDistricts,
    loading: loadingDistricts,
    message: messageDistricts,
    messageTitle: messageTitleDistricts,
  } = useSelector((s) => s.Districts);
  const { provinceId, regionId } = useSelector((s) => s.FormKios);
  const { data, success } = useSelector((s) => s.AddKios);
  const formdata = useSelector((s) => s.FormKios);

  React.useEffect(() => {
    if (provinceId.length > 0) dispatch(fetchRegencies({}, provinceId));
  }, [provinceId]);

  React.useEffect(() => {
    if (regionId.length > 0) dispatch(fetchDistricts({}, regionId));
  }, [regionId]);

  const onChange = (e, val) => {
    const name = e.target?.name || e;
    const value = e.target?.value || val;

    dispatch(handleChangeKios(name, value));
  };

  return (
    <Form
      data-testid="kios-address"
      aria-autocomplete="none"
      autoComplete="off"
    >
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label htmlFor="province">Provinsi</Label>
            <Select
              onChange={(e) => onChange("provinceId", e.value)}
              options={dataProvinces}
              classNamePrefix="select2-selection"
              isLoading={loadingProvinces}
              noOptionsMessage={() =>
                `${messageTitleProvinces}: ${messageProvinces}`
              }
              placeholder="Select Your Province"
              name="province"
              inputId="province"
              value={dataProvinces.filter(
                (i) => i.value == formdata.provinceId
              )}
            />
            {data && !success && (
              <small className="text-danger">{data["provinceId"][0]}</small>
            )}
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-vatno-input6">Kota</Label>
            <Select
              onChange={(e) => onChange("regionId", e.value)}
              options={dataRegencies}
              classNamePrefix="select2-selection"
              isLoading={loadingRegencies}
              noOptionsMessage={() =>
                `${messageTitleRegencies}: ${messageRegencies}`
              }
              placeholder="Select Your City"
              value={dataRegencies.filter((i) => i.value == formdata.regionId)}
            />
            {data && !success && (
              <small className="text-danger">{data["regionId"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-cstno-input7">Kecamatan</Label>
            <Select
              onChange={(e) => onChange("districtId", e.value)}
              options={dataDistricts}
              classNamePrefix="select2-selection"
              isLoading={loadingDistricts}
              noOptionsMessage={() =>
                `${messageTitleDistricts}: ${messageDistricts}`
              }
              placeholder="Select Your District"
              value={dataDistricts.filter(
                (i) => i.value == formdata.districtId
              )}
            />
            {data && !success && (
              <small className="text-danger">{data["districtId"][0]}</small>
            )}
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-companyuin-input9">Kode Pos</Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-companyuin-input9"
              placeholder="Enter Your Postal Code"
              name="postalCode"
              onChange={onChange}
              value={formdata.postalCode}
            />
            {data && !success && (
              <small className="text-danger">{data["postalCode"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <div className="mb-3">
            <Label for="basicpill-declaration-input10">Alamat Lengkap</Label>
            <textarea
              type="text"
              className="form-control"
              id="basicpill-Declaration-input10"
              placeholder="Enter Your Address"
              name="address"
              onChange={onChange}
              value={formdata.address}
            ></textarea>
            {data && !success && (
              <small className="text-danger">{data["address"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default KiosAddress;
