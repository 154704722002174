import {
  FETCH_ACADEMY_BANNER,
  FETCH_ACADEMY_BANNER_SUCCESS,
  FETCH_ACADEMY_BANNER_FAILED,
} from "./actionTypes";

export const fetchAcademyBanner = (params) => ({
  type: FETCH_ACADEMY_BANNER,
  payload: { params },
});

export const fetchAcademyBannerSuccess = (data) => ({
  type: FETCH_ACADEMY_BANNER_SUCCESS,
  payload: data,
});

export const fetchAcademyBannerFailed = (error) => ({
  type: FETCH_ACADEMY_BANNER_FAILED,
  payload: error,
});
