import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import Pagination from "../../components/Pagination";
import columns from "./columns";
import Filter from "./filter";
import { useDispatch, useSelector } from "react-redux";
import {
  paramsCleanup,
  handleChange,
  payloadFormCleanup,
  fetchParams,
  fetchPromo,
  updatePromoCleanup,
  updatePromo,
} from "../../store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import ModalForm from "./modalForm";
import useToggleModal from "./hooks/useToggleModal";
import { toast, ToastContainer } from "react-toastify";
import CustomToast from "components/Common/CustomToast";

import "./style.scss";
import SweetAlert from "components/Common/SweetAlert";

const dummyData = {
  slug: "harga-coret",
  title: "Harga Coret",
  url: null,
  categoryId: 10,
  order: 1,
  isHomepage: 1,
  status: 1,
  createdAt: "2023-02-25T16:00:41.000Z",
  updatedAt: null,
  deletedAt: null,
  category_id: 10,
  category: {
    id: 10,
    name: "Pulsa",
  },
  statusString: "aktif",
};

const Promo = () => {
  //meta title
  document.title = "Promo | Pulsadong";

  const dispatch = useDispatch();
  const params = useSelector((s) => s.Params);
  const { data, loading, total } = useSelector((s) => s.GetPromo);
  const {
    message,
    messageTitle,
    success,
    loading: loadingUpdate,
  } = useSelector((s) => s.UpdatePromo);
  const {
    message: msgPost,
    messageTitle: msgTitlePost,
    success: successPost,
  } = useSelector((s) => s.PostPromo);
  const promoProps = useSelector((s) => s.Promo);
  const {
    showModal,
    toggle,
    type,
    showConfirm,
    toggleConfirm,
    setShowConfirm,
    setShowModal,
  } = useToggleModal();

  React.useEffect(() => {
    dispatch(fetchParams());

    return () => {
      dispatch(paramsCleanup());
      dispatch(payloadFormCleanup());
    };
  }, []);

  React.useEffect(() => {
    dispatch(fetchPromo(params));
  }, [params.page, params.per_page, params.search, params.status]);

  React.useEffect(() => {
    if (message) {
      toast(
        <CustomToast
          title={messageTitle}
          desc={message}
          type={success ? "success" : "error"}
        />
      );
      dispatch(updatePromoCleanup());
      dispatch(fetchPromo(params));
    }
  }, [message]);

  React.useEffect(() => {
    if (successPost) {
      toast(
        <CustomToast
          title={msgTitlePost}
          desc={msgPost}
          type={successPost ? "success" : "error"}
        />
      );
      dispatch(updatePromoCleanup());
      dispatch(fetchPromo(params));
    }
  }, [successPost]);

  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onSubmitUpdateStatus = () => {
    dispatch(
      updatePromo(promoProps.id, { ...promoProps, status: promoProps.status })
    );
  };

  const onCancelUpdateStatus = () => {
    toggleConfirm();
    dispatch(payloadFormCleanup());
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Daftar Promo" />
          <Card>
            <CardBody className={loading ? "sekeleton active" : ""}>
              <Filter openFormAdd={() => toggle(null, "add")} />
              <DataTable
                noHeader
                sortServer
                paginationServer
                columns={columns({ toggle, toggleConfirm })}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
              />
              <Pagination
                totalRow={total}
                perPage={params.perPage}
                pageCount={Math.ceil(total / params.perPage)}
                forcePage={params.page - 1}
                onPageChange={onPageChange}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      <ModalForm
        isOpen={showModal}
        toggle={toggle}
        type={type}
        setShowConfirm={setShowConfirm}
        setShowModal={setShowModal}
      />
      <ToastContainer autoClose={3000} position="bottom-right" />
      <SweetAlert
        loading={loadingUpdate}
        show={showConfirm}
        title={`${promoProps.status ? "Aktifkan" : "Nonaktifkan"} Promo?`}
        message={`Promo akan ${
          promoProps.status ? "diaktifkan" : "dinonaktifkan"
        } setelah dikonfirmasi`}
        submitLabel="Konfirmasi"
        cancelLabel="Batal"
        onSubmit={onSubmitUpdateStatus}
        onCancel={onCancelUpdateStatus}
      />
    </React.Fragment>
  );
};

export default Promo;
