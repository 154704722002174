import React from "react";
import { Form, Row, Col, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeKios } from "store/actions";

const KiosDetail = () => {
  const dispatch = useDispatch();
  const { data, success } = useSelector((s) => s.AddKios);
  const formdata = useSelector((s) => s.FormKios);
  const pinRef = React.useRef();
  const [pin, setPin] = React.useState("");
  const [validationPin, setValidationPin] = React.useState(null);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "pin") {
      if (isNaN(value)) {
        setPin("");
        setValidationPin({
          message: "Pin harus berupa angka",
        });
        return;
      }

      setPin(value);
      setValidationPin(null);
      dispatch(handleChangeKios(name, value));

      return;
    }

    dispatch(handleChangeKios(name, value));
  };

  return (
    <Form aria-autocomplete="none" autoComplete="off">
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-firstname-input1">First name</Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-firstname-input1"
              placeholder="Enter Your First Name"
              name="firstName"
              onChange={onChange}
              data-testid="input-firstname"
              value={formdata.firstName}
            />
            {data && !success && (
              <small className="text-danger">{data["firstName"][0]}</small>
            )}
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-lastname-input2">Last name</Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-lastname-input2"
              placeholder="Enter Your Last Name"
              name="lastName"
              onChange={onChange}
              data-testid="input-lastname"
              value={formdata.lastName}
            />
            {data && !success && (
              <small className="text-danger">{data["lastName"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-phoneno-input3">Phone</Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-phoneno-input3"
              placeholder="Enter Your Phone No."
              name="phone"
              onChange={onChange}
              data-testid="input-phone"
              value={formdata.phone}
            />
            {data && !success && (
              <small className="text-danger">{data["phone"][0]}</small>
            )}
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-email-input4">Email</Label>
            <Input
              type="email"
              className="form-control"
              id="basicpill-email-input4"
              placeholder="Enter Your Email ID"
              name="email"
              onChange={onChange}
              data-testid="input-email"
              value={formdata.email}
            />
            {data && !success && (
              <small className="text-danger">{data["email"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-address-input1">Pin Transaksi</Label>
            <Input
              type="password"
              placeholder="Enter Your Pin"
              name="pin"
              onChange={onChange}
              maxLength={6}
              ref={pinRef}
              value={pin}
            />
            {validationPin ? (
              <small className="text-danger">{validationPin?.message}</small>
            ) : (
              data &&
              !success && (
                <small className="text-danger">{data["pin"][0]}</small>
              )
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default KiosDetail;
