import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  LOGOUT_USER,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  localStorage.setItem("authUser", user.data.accessToken);
  localStorage.setItem("user", JSON.stringify(user.data.userData));

  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error.response.data.message,
  };
};
