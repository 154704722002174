import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import Filter from "./filter";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryList,
  fetchProductList,
  handleChange,
  updateProductListClean,
  updateStatusProductList,
  updateStatusProductListClean,
} from "store/actions";
import columns from "./columns";
import DataTable from "react-data-table-component";
import Pagination from "../../components/Pagination";
import ModalEdit from "./modalEdit";
import CustomToast from "components/Common/CustomToast";
import { toast, ToastContainer } from "react-toastify";
import SweetAlert from "components/Common/SweetAlert";

const DaftarProduk = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    sectionId: 0,
    name: "",
    icon: "",
    status: 1,
    orderNo: 0,
    id: 0,
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [dataStatus, setDataStatus] = useState({
    status: 0,
  });
  const [id, setId] = useState(0);
  const dispatch = useDispatch();
  const { data, loading, total } = useSelector((s) => s.DaftarProduk);
  const {
    data: categories,
    message: messageCategory,
    messageTitle: messageTitleCategory,
    success,
  } = useSelector((s) => s.CategoryList);
  const params = useSelector((s) => s.Params);
  const {
    message: messageUpdate,
    messageTitle: messageTitleUpdate,
    success: successUpdate,
  } = useSelector((s) => s.UpdateProductList);
  const {
    message: messageUpdateStatus,
    messageTitle: messageTitleUpdateStatus,
    success: successUpdateStatus,
    loading: loadingUpdateStatus,
  } = useSelector((s) => s.UpdateStatusProductList);
  const dummyData = {
    slug: "pulsa",
    name: "Pulsa",
    nameHomepage: "Pulsa",
    icon: "https://firebasestorage.googleapis.com/v0/b/pulsadong.appspot.com/o/mobile_1658643923621.svg?alt=media&token=48c4be52-dff2-4f08-932b-ce8a72f2e6de",
    sectionId: 1,
    status: 1,
    orderNo: 0,
    createdAt: "2022-07-02T01:18:19.000Z",
    updatedAt: "2022-08-22T12:36:26.000Z",
    section_id: 1,
    product_sections: {
      name: "Prepaid & Pascabayar",
    },
    statusString: "aktif",
  };

  useEffect(() => {
    dispatch(fetchProductList(params));
  }, [params]);

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, []);

  useEffect(() => {
    if (messageUpdate != "") {
      toast(
        <CustomToast
          title={messageTitleUpdate}
          desc={messageUpdate}
          type={successUpdate ? "success" : "error"}
        />
      );

      dispatch(updateProductListClean());
      dispatch(fetchProductList(params));
    }
  }, [messageUpdate]);

  useEffect(() => {
    if (messageUpdateStatus != "") {
      toast(
        <CustomToast
          title={messageTitleUpdateStatus}
          desc={messageUpdateStatus}
          type={successUpdateStatus ? "success" : "error"}
        />
      );

      dispatch(updateStatusProductListClean());
      dispatch(fetchProductList(params));
      setShowConfirm(false);
    }
  }, [messageUpdateStatus]);

  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onEdit = (rowData) => {
    setShowModal(true);
    setFormData((prev) => ({
      ...prev,
      sectionId: rowData.sectionId,
      name: rowData.name,
      icon: rowData.icon,
      orderNo: rowData.orderNo,
      status: rowData.status,
      id: rowData.id,
    }));
  };

  const onCloseEdit = () => {
    setShowModal(false);
  };

  const onChangeStatus = (id, status) => {
    setShowConfirm(true);
    setDataStatus((prev) => ({
      ...prev,
      status: status === 1 ? 0 : 1,
    }));
    setId(id);
  };

  const onConfirmChangeStatus = () => {
    dispatch(updateStatusProductList(dataStatus, id));
  };

  const onCancelChangeStatus = () => {
    setShowConfirm(false);
    setDataStatus({ status: 0 });
    setId(0);
  };

  return (
    <React.Fragment>
      <div className="page-content" id="kios">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Daftar Produk" />
          <Card>
            <CardBody>
              <Filter />
              <DataTable
                noHeader
                sortServer
                paginationServer
                columns={columns({ onEdit, onChangeStatus })}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ModalEdit
        categories={categories}
        isOpen={showModal}
        message={messageCategory}
        messageTitle={messageTitleCategory}
        success={success}
        formData={formData}
        onClose={onCloseEdit}
      />
      <SweetAlert
        show={showConfirm}
        loading={loadingUpdateStatus}
        title="Update Status Produk"
        message="Apa kamu yakin?"
        onSubmit={onConfirmChangeStatus}
        onCancel={onCancelChangeStatus}
      />
      <ToastContainer position="bottom-right" progressClassName="progressbar" />
    </React.Fragment>
  );
};

export default DaftarProduk;
