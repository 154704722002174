import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import dashboardSaga from "./dashboard/saga";
import topup from "./topup/saga";
import ParamsSaga from "./params/saga";
import KiosSaga from "./kios/saga";
import MasterSaga from "./master/saga";
import DaftarHargaSaga from "./daftarHarga/saga";
import Transaction from "./transaction/saga";
import Balance from "./balance/saga";
import DaftarProduk from "./daftarProduk/saga";
import PromoSaga from "./promo/saga";
import AcademyBannerSaga from "./academy/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(topup),
    fork(ParamsSaga),
    fork(KiosSaga),
    fork(MasterSaga),
    fork(DaftarHargaSaga),
    fork(Transaction),
    fork(Balance),
    fork(DaftarProduk),
    fork(PromoSaga),
    fork(AcademyBannerSaga),
  ]);
}
