export const FETCH_BANKS = "FETCH_BANKS";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAILED = "FETCH_BANKS_FAILED";
export const FETCH_PROVINCES = "FETCH_PROVINCES";
export const FETCH_PROVINCES_SUCCESS = "FETCH_PROVINCES_SUCCESS";
export const FETCH_PROVINCES_FAILED = "FETCH_PROVINCES_FAILED";
export const FETCH_REGENCIES = "FETCH_REGENCIES";
export const FETCH_REGENCIES_SUCCESS = "FETCH_REGENCIES_SUCCESS";
export const FETCH_REGENCIES_FAILED = "FETCH_REGENCIES_FAILED";
export const FETCH_DISTRICTS = "FETCH_DISTRICTS";
export const FETCH_DISTRICTS_SUCCESS = "FETCH_DISTRICTS_SUCCESS";
export const FETCH_DISTRICTS_FAILED = "FETCH_DISTRICTS_FAILED";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_CLEANUP = "UPLOAD_IMAGE_CLEANUP";
export const FETCH_PARAMS = "FETCH_PARAMS";
export const FETCH_PARAMS_SUCCESS = "FETCH_PARAMS_SUCCESS";
export const FETCH_PARAMS_FAILED = "FETCH_PARAMS_FAILED";
