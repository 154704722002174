import { FETCH_BALANCE } from "./actionTypes";

const { fetchBalance } = require("helpers/dashboard_helper");
const { call, put, takeEvery } = require("redux-saga/effects");
const { fetchBalanceSuccess, fetchBalanceFailed } = require("./actions");

function* getBalance() {
  try {
    const response = yield call(fetchBalance);

    yield put(fetchBalanceSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchBalanceFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchBalanceFailed(error.response.data));
    }
  }
}

function* BalanceSaga() {
  yield takeEvery(FETCH_BALANCE, getBalance);
}

export default BalanceSaga;
