const { encodeQueryData } = require("helper");
const { get, postForm, put } = require("./api_helper");
const {
  FETCH_BANK,
  FETCH_PROVINCES,
  FETCH_REGENCIES,
  FETCH_DISTRICTS,
  UPLOAD_IMAGE,
  FETCH_PROMO_URL,
} = require("./constant");

const fetchBank = (params) =>
  get(
    `${FETCH_BANK}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const fetchProvinces = (params) =>
  get(
    `${FETCH_PROVINCES}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const fetchRegencies = (params, provinceId) =>
  get(
    `${FETCH_REGENCIES}/${provinceId}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const fetchDistricts = (params, regencyId) =>
  get(
    `${FETCH_DISTRICTS}/${regencyId}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const uploadImage = (data, config = {}) =>
  postForm(`${UPLOAD_IMAGE}`, data, config);

const fetchParams = () => get(`${FETCH_PROMO_URL}/params`);

export {
  fetchBank,
  fetchProvinces,
  fetchRegencies,
  fetchDistricts,
  uploadImage,
  fetchParams,
};
