import { priceDecimal } from "helper";
import React from "react";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const { useSelector } = require("react-redux");
import PropTypes from "prop-types";

const columns = ({ onOpenEditPrice, onOpenUpdateStatus }) => {
  const { page, perPage } = useSelector((s) => s.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (_, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "SKU ID",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.buyerSkuCode}
        </span>
      ),
    },
    {
      name: "Brand",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.brand.name}
        </span>
      ),
    },
    {
      name: "Name",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.productName}
        </span>
      ),
    },
    {
      name: "Harga Modal",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp. {row.priceFormatted}
        </span>
      ),
    },
    {
      name: "Harga Jual",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp. {row.sellingPriceFormatted}
        </span>
      ),
    },
    {
      name: "Profit",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp. {priceDecimal(row?.sellingPrice - row?.price)}
        </span>
      ),
    },
    {
      name: "Stock",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.stock === 1 ? "Limited" : "Unlimited"}
        </span>
      ),
    },
    {
      name: "Status",
      width: "120px",
      cell: (row) => (
        <Badge color={row.status ? "success" : "danger"} className="obj-el">
          {row.status === 1 ? "Aktif" : "Nonaktif"}
        </Badge>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a">
            <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem
              onClick={() =>
                onOpenUpdateStatus(row.status === 1 ? 0 : 1, row.id)
              }
            >
              {row.status === 1 ? "Nonaktifkan" : "Aktifkan"}
            </DropdownItem>
            <DropdownItem onClick={() => onOpenEditPrice(row)}>
              Ubah Harga
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
};

columns.propTypes = {
  onOpenEditPrice: PropTypes.func,
  onOpenUpdateStatus: PropTypes.func,
};

export default columns;
