import { get, put } from "./api_helper";
import {
  EXPORT_EXCEL_TOPUP,
  FETCH_TOPUP,
  FETCH_TOPUP_HISTORY,
  UPDATE_STATUS_TOPUP,
} from "./constant";
import { encodeQueryData } from "../helper";

const fetchTopup = (params) =>
  get(
    `${FETCH_TOPUP}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const updateStatusTopup = (data, id) =>
  put(`${UPDATE_STATUS_TOPUP}/${id}`, data);

const exportTopup = (params) =>
  get(
    `${EXPORT_EXCEL_TOPUP}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const fetchTopupHistory = (params, id, config) =>
  get(
    `${FETCH_TOPUP_HISTORY}/${id}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`,
    config
  );

export { fetchTopup, updateStatusTopup, exportTopup, fetchTopupHistory };
