import { priceDecimal, isNegative } from "helper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody } from "reactstrap";
import { fetchSummary } from "store/actions";

const SummaryCard = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.Dashboard);

  React.useEffect(() => {
    dispatch(fetchSummary());
  }, []);

  return (
    <Row>
      {data.map((report, key) => (
        <Col xs={12} md={4} key={key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <Row className="align-items-center mb-2">
                <Col xs="auto">
                  <div className="rounded-circle bg-info align-self-center mini-stat-icon stat-icon">
                    <span className="rounded-circle bg-info h-100 d-flex align-items-center justify-content-center">
                      <i className={"bx " + report.iconClass}></i>
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="flex-grow-1">
                    <h6 className="mb-0">{report.title}</h6>
                  </div>
                </Col>
              </Row>
              <div className="d-flex align-items-center mb-2">
                <h4 className="mb-0">{priceDecimal(report.value)}</h4>
                <i
                  className={`bx ${
                    isNegative(report.percentage)
                      ? "bx-chevron-down text-danger"
                      : "bx-chevron-up text-success"
                  } fs-3 fw-bold ms-2`}
                ></i>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className={`badge ${
                    isNegative(report.percentage)
                      ? "badge-soft-danger"
                      : "badge-soft-success"
                  }`}
                >
                  {report.percentage}%
                </div>
                <div className="label-period">From previous period</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SummaryCard;
