import { encodeQueryData } from "helper";
import { get, post, put } from "./api_helper";
import {
  ADD_KIOS,
  EDIT_KIOS,
  FETCH_KIOS,
  UPDATE_STATUS_KIOS,
  UPDATE_VERIFICATION_KIOS,
} from "./constant";

const fetchKios = (params) =>
  get(
    `${FETCH_KIOS}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const addKios = (data) => post(`${ADD_KIOS}`, data);

const updateStatusKios = (data, id) => put(`${UPDATE_STATUS_KIOS}/${id}`, data);

const editKios = (id) => get(`${EDIT_KIOS}/${id}`);

const updateKios = (data, id) => put(`${EDIT_KIOS}/${id}`, data);

const updateVerification = (data, id) =>
  put(`${UPDATE_VERIFICATION_KIOS}/${id}`, data);

export {
  fetchKios,
  addKios,
  updateStatusKios,
  editKios,
  updateKios,
  updateVerification,
};
