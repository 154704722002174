import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import {
  fetchKios,
  handleChange,
  updateStatusKios,
  updateStatusKiosCleanup,
  paramsCleanup,
  updateVerification,
  updateVerificationCleanup,
} from "../../store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import columns from "./columns";
import Pagination from "../../components/Pagination";
import Filter from "./filter";
import SweetAlert from "components/Common/SweetAlert";
import { toast, ToastContainer } from "react-toastify";
import CustomToast from "components/Common/CustomToast";
import { useHistory } from "react-router-dom";
import "./style.scss";

const dummyData = {
  phone: "89682441714",
  firstName: "Armyy",
  lastName: "Cellular",
  logo: null,
  email: "test4@gmail.com",
  kiosName: null,
  regionId: "5104",
  status: 0,
  ktp: "urlktp",
  provinceId: "51",
  districtId: "5104020",
  address: "test alamat",
  bank: "1",
  bankBranch: "Dps",
  accountNumber: "1122334455",
  accountName: "Irvan DP",
  postalCode: "80514",
  profilePicture: null,
  userId: 5,
  createdAt: "2022-07-25T14:34:20.000Z",
  updatedAt: "2022-08-25T14:06:16.000Z",
  province: {
    id: "51",
    name: "BALI",
  },
  regency: {
    id: "5104",
    provinceId: "51",
    name: "KABUPATEN GIANYAR",
  },
  district: {
    id: "5104020",
    regencyId: "5104",
    name: "BLAHBATUH",
  },
};

const alertTitle = {
  1: "Aktifkan Kios",
  0: "Nonaktikan Kios",
};

const alertTitleVerification = {
  VRJC: "Tolak Verifikasi",
  VAPR: "Setujui Verifikasi",
};

const Kios = () => {
  document.title = "Kios | Pulsadong";

  const [formStatus, setFormStatus] = React.useState({
    status: 0,
    id: null,
  });
  const [formVerification, setFormVerification] = React.useState({
    status: "",
    id: null,
  });
  const [openAlert, setOpenAlert] = React.useState("");
  const dispatch = useDispatch();
  const { data, loading, total } = useSelector((s) => s.Kios);
  const params = useSelector((s) => s.Params);
  const {
    data: dataUpdateStatus,
    message,
    messageTitle,
    success,
    loading: loadingUpdateStatus,
  } = useSelector((s) => s.UpdateStatusKios);
  const {
    data: dataUpdateVerification,
    message: messageUpdateVerification,
    messageTitle: messageUpdateVerificationTitle,
    success: successUpdateVerification,
    loading: loadingUpdateVerification,
  } = useSelector((s) => s.UpdateVerification);
  const history = useHistory();

  React.useEffect(() => {
    return () => {
      dispatch(paramsCleanup());
    };
  }, []);

  React.useEffect(() => {
    dispatch(fetchKios(params));
  }, [params]);

  React.useEffect(() => {
    if (dataUpdateStatus) {
      setOpenAlert("");
      toast(
        <CustomToast
          title={messageTitle}
          desc={message}
          type={success ? "success" : "error"}
        />
      );
      dispatch(updateStatusKiosCleanup());
      dispatch(fetchKios(params));
    }
  }, [dataUpdateStatus]);

  React.useEffect(() => {
    if (dataUpdateVerification) {
      setOpenAlert("");
      toast(
        <CustomToast
          title={messageUpdateVerificationTitle}
          desc={messageUpdateVerification}
          type={successUpdateVerification ? "success" : "error"}
        />
      );
      dispatch(updateVerificationCleanup());
      dispatch(fetchKios(params));
    }
  }, [dataUpdateVerification]);

  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onUpdateStatus = (id, status) => {
    setFormStatus((prev) => ({ ...prev, id, status }));
    setOpenAlert("update-status");
  };

  const onSubmitUpdateStatus = () => {
    const { status, id } = formStatus;

    dispatch(updateStatusKios({ status }, id));
  };

  const onCancelUpdatestatus = () => {
    setOpenAlert("");
    setFormStatus({ status: 0, id: null });
  };

  const onEditData = (id) => {
    history.push(`/kios/edit/${id}`);
  };

  const onUpdateVerification = (status, id) => {
    setOpenAlert("update-verification");
    setFormVerification({
      status,
      id,
    });
  };

  const onSubmitUpdateVerification = () => {
    const { status, id } = formVerification;

    dispatch(updateVerification({ status }, id));
  };

  const onCancelUpdateVerification = () => {
    setOpenAlert("");
    setFormVerification({ status: "", id: null });
  };

  return (
    <React.Fragment>
      <div className="page-content" id="kios">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Kios Pulsadong" />
          <Card>
            <CardBody className={loading ? "sekeleton active" : ""}>
              <Filter />
              <DataTable
                noHeader
                sortServer
                paginationServer
                columns={columns({
                  onUpdateStatus,
                  onEditData,
                  onUpdateVerification,
                })}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SweetAlert
        show={openAlert === "update-status"}
        title={alertTitle[formStatus.status]}
        message="Apa kamu yakin?"
        type="confirm"
        onSubmit={onSubmitUpdateStatus}
        onCancel={onCancelUpdatestatus}
        loading={loadingUpdateStatus}
      />
      <SweetAlert
        show={openAlert === "update-verification"}
        title={alertTitleVerification[formVerification.status]}
        message="Apa kamu yakin?"
        type="confirm"
        onSubmit={onSubmitUpdateVerification}
        onCancel={onCancelUpdateVerification}
        loading={loadingUpdateVerification}
      />
      <ToastContainer
        position="bottom-right"
        progressClassName="progressbar"
        autoClose={2000}
      />
    </React.Fragment>
  );
};

export default Kios;
