import { formatRupiah } from "helper";
import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateHargaJual, updateHargaJualCleanup } from "store/actions";

const ModalEdit = ({ isOpen, toggle, data }) => {
  const [formData, setFormData] = React.useState({
    price: "",
    sellingPrice: "",
  });
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.UpdateHargaJual);

  React.useEffect(() => {
    return () => {
      dispatch(updateHargaJualCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      setFormData((prev) => ({
        ...prev,
        price: data.price.toString(),
        sellingPrice: data.sellingPrice.toString(),
      }));
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    const currentObj = formData;
    const splitSellingPrice = currentObj.sellingPrice.split(" ")[1];
    const sellingPrice = splitSellingPrice.split(".").join("");

    dispatch(updateHargaJual({ sellingPrice }, data.id));
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Ubah Harga #{data.buyerSkuCode}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Harga Modal</Label>
            <Input
              disabled
              value={formatRupiah(formData.price, "Rp")}
              placeholder="Masukan Harga Modal"
            />
          </FormGroup>
          <FormGroup>
            <Label>Harga Jual</Label>
            <Input
              value={formatRupiah(formData.sellingPrice, "Rp")}
              placeholder="Masukan Harga Jual"
              name="sellingPrice"
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button color="danger" outline>
              Batal
            </Button>
          </Col>
          <Col xs="auto">
            <Button color="warning" onClick={onSubmit}>
              <Row className="align-items-center">
                <Col xs="auto" className={loading ? "pe-0" : ""}>
                  {loading ? "Loading" : "Simpan"}
                </Col>
                {loading && (
                  <Col xs="auto">
                    <Spinner size="sm" />
                  </Col>
                )}
              </Row>
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ModalEdit.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  data: PropTypes.object,
};

ModalEdit.defaultProps = {
  isOpen: false,
  data: {
    id: null,
    buyerSkuCode: null,
    price: null,
    sellingPrice: null,
  },
};

export default ModalEdit;
