import WelcomeComp from "components/WelcomeComp";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import SummaryCard from "./SummaryCard";
import "./dashboard.scss";
import TopSellingProduct from "./TopSellingProduct";
import TransactionAnalytics from "./TransactionAnalytics";
import LatestTopup from "./LatestTopup";

const Dashboard = () => {
  //meta title
  document.title = "Dashboard | Pulsadong";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Dashboard</h4>
          <Row>
            <Col md={12} xl={4}>
              <WelcomeComp />
            </Col>
            <Col>
              <SummaryCard />
            </Col>
          </Row>
          <Row>
            <TopSellingProduct />
            <LatestTopup />
            <TransactionAnalytics />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
