const {
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILED,
  FETCH_CATEGORY_LIST,
  FETCH_CATEGORY_LIST_SUCCESS,
  FETCH_CATEGORY_LIST_FAILED,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCT_LIST_FAILED,
  UPDATE_PRODUCT_LIST_CLEAN,
  UPDATE_STATUS_PRODUCT_LIST,
  UPDATE_STATUS_PRODUCT_LIST_SUCCESS,
  UPDATE_STATUS_PRODUCT_LIST_FAILED,
  UPDATE_STATUS_PRODUCT_LIST_CLEAN,
} = require("./actionTypes");

const INIT_STATE = {
  data: [],
  loading: false,
  total: 0,
  message: null,
};

const INIT_STATE_CATEGORY_LIST = {
  data: [],
  loading: false,
  message: "",
  messageTitle: "",
  success: false,
};

const INIT_STATE_UPDATE = {
  data: null,
  success: false,
  message: "",
  messageTitle: "",
  loading: false,
};

const DaftarProduk = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.total,
      };
    case FETCH_PRODUCT_LIST_FAILED:
      return {
        ...state,
        data: [],
        loading: false,
        total: 0,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export const CategoryList = (state = INIT_STATE_CATEGORY_LIST, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        success: true,
      };
    case FETCH_CATEGORY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: false,
      };
    default:
      return state;
  }
};

export const UpdateProductList = (state = INIT_STATE_UPDATE, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_PRODUCT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_PRODUCT_LIST_CLEAN:
      return INIT_STATE_UPDATE;
    default:
      return state;
  }
};

export const UpdateStatusProductList = (state = INIT_STATE_UPDATE, action) => {
  switch (action.type) {
    case UPDATE_STATUS_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_STATUS_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_STATUS_PRODUCT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_STATUS_PRODUCT_LIST_CLEAN:
      return INIT_STATE_UPDATE;
    default:
      return state;
  }
};

export default DaftarProduk;
