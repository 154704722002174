import { fetchAcademyBanner } from "helpers/academy_helper";
import { FETCH_ACADEMY_BANNER } from "./actionTypes";

const { call, put, takeEvery } = require("redux-saga/effects");
const {
  fetchAcademyBannerSuccess,
  fetchAcademyBannerFailed,
} = require("./actions");

function* getAcademyBanner({ payload: { params } }) {
  try {
    const response = yield call(fetchAcademyBanner, params);

    yield put(fetchAcademyBannerSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchAcademyBannerFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchAcademyBannerFailed(error.response.data));
    }
  }
}

function* AcademyBannerSaga() {
  yield takeEvery(FETCH_ACADEMY_BANNER, getAcademyBanner);
}

export default AcademyBannerSaga;
