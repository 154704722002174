const {
  fetchBank,
  fetchProvinces,
  fetchRegencies,
  fetchDistricts,
  uploadImage,
  fetchParams,
} = require("helpers/master_helper");
const { call, put, takeEvery } = require("redux-saga/effects");
const {
  fetchBanksSuccess,
  fetchBanksFailed,
  fetchProvincesSuccess,
  fetchRegenciesSuccess,
  fetchRegenciesFailed,
  fetchDistrictsSuccess,
  fetchDistrictsFailed,
  uploadImageSuccess,
  uploadImageFailed,
  fetchParamsSuccess,
  fetchParamsFailed,
} = require("./actions");
const {
  FETCH_BANKS,
  FETCH_PROVINCES,
  FETCH_REGENCIES,
  FETCH_DISTRICTS,
  UPLOAD_IMAGE,
  FETCH_PARAMS,
} = require("./actionTypes");

function* getBankData({ payload: { params } }) {
  try {
    const response = yield call(fetchBank, params);

    yield put(fetchBanksSuccess(response));
  } catch (error) {
    yield put(fetchBanksFailed(error.response.data));
  }
}

function* getProvinces({ payload: { params } }) {
  try {
    const response = yield call(fetchProvinces, params);

    yield put(fetchProvincesSuccess(response));
  } catch (error) {
    yield put(fetchProvincesSuccess(error.response.data));
  }
}

function* getRegencies({ payload: { params, provinceId } }) {
  try {
    const response = yield call(fetchRegencies, params, provinceId);

    yield put(fetchRegenciesSuccess(response));
  } catch (error) {
    yield put(fetchRegenciesFailed(error.response.data));
  }
}

function* getDistricts({ payload: { params, regencyId } }) {
  try {
    const response = yield call(fetchDistricts, params, regencyId);

    yield put(fetchDistrictsSuccess(response));
  } catch (error) {
    yield put(fetchDistrictsFailed(error.response.data));
  }
}

function* postUploadImage({ payload: { data, config, name } }) {
  try {
    const response = yield call(uploadImage, data, config);

    yield put(uploadImageSuccess({ ...response, name }));
  } catch (error) {
    yield put(uploadImageFailed(error.response.data));
  }
}

function* getParams() {
  try {
    const response = yield call(fetchParams);

    yield put(fetchParamsSuccess(response));
  } catch (error) {
    yield put(fetchParamsFailed(error.response.data));
  }
}

function* MasterSaga() {
  yield takeEvery(FETCH_BANKS, getBankData);
  yield takeEvery(FETCH_PROVINCES, getProvinces);
  yield takeEvery(FETCH_REGENCIES, getRegencies);
  yield takeEvery(FETCH_DISTRICTS, getDistricts);
  yield takeEvery(UPLOAD_IMAGE, postUploadImage);
  yield takeEvery(FETCH_PARAMS, getParams);
}

export default MasterSaga;
