export const FETCH_TOPUP_SUCCESS = "FETCH_TOPUP_SUCCESS";
export const FETCH_TOPUP_FAILED = "FETCH_TOPUP_FAILED";
export const FETCH_TOPUP = "FETCH_TOPUP";
export const UPDATE_STATUS_TOPUP = "UPDATE_STATUS_TOPUP";
export const UPDATE_STATUS_TOPUP_SUCCESS = "UPDATE_STATUS_TOPUP_SUCCESS";
export const UPDATE_STATUS_TOPUP_FAILED = "UPDATE_STATUS_TOPUP_FAILED";
export const UPDATE_STATUS_CLEAN_UP = "UPDATE_STATUS_CLEAN_UP";
export const EXPORT_TOPUP_EXCEL = "EXPORT_TOPUP_EXCEL";
export const EXPORT_TOPUP_EXCEL_SUCCESS = "EXPORT_TOPUP_EXCEL_SUCCESS";
export const EXPORT_TOPUP_EXCEL_FAILED = "EXPORT_TOPUP_EXCEL_FAILED";
export const EXPORT_TOPUP_EXCEL_CLEAN_UP = "EXPORT_TOPUP_EXCEL_CLEAN_UP";
export const FETCH_TOPUP_HISTORY = "FETCH_TOPUP_HISTORY";
export const FETCH_TOPUP_HISTORY_SUCCESS = "FETCH_TOPUP_HISTORY_SUCCESS";
export const FETCH_TOPUP_HISTORY_FAILED = "FETCH_TOPUP_HISTORY_FAILED";
