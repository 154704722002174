import React from "react";
import { Toast, ToastBody, ToastHeader, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

const CustomToast = ({ title, desc, type }) => {
  return (
    <Toast className={type === "error" ? "error" : "success"}>
      <Row>
        <Col xs="auto" className="toastBell">
          <i className="mdi mdi-bell"></i>
        </Col>
        <Col>
          <ToastHeader>
            {title || (type === "error" ? "Error" : "Berhasil")}
          </ToastHeader>
          {desc !== "" && (
            <ToastBody>
              {desc ||
                (type === "error"
                  ? "Silahkan coba lagi"
                  : "Silahkan periksa kembali")}
            </ToastBody>
          )}
        </Col>
      </Row>
    </Toast>
  );
};

CustomToast.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  type: PropTypes.string,
};

export default CustomToast;
