import { HANDLE_CHANGE, PARAMS_CLEANUP } from "./actionTypes";

export const handleChange = (name, value) => ({
  type: HANDLE_CHANGE,
  payload: { name, value },
});

export const paramsCleanup = () => ({
  type: PARAMS_CLEANUP,
});
