import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";

const FormSelect = ({
  label = "",
  options = [],
  placeholder = "",
  value = "",
  name = "",
  message = null,
  ...selectArgs
}) => {
  const [val, setVal] = React.useState(value || "");

  return (
    <FormGroup>
      <Label>{label}</Label>
      <input type="hidden" name={name} value={val} />
      <Select
        {...selectArgs}
        placeholder={placeholder}
        options={options}
        onChange={(e) => setVal(e.value)}
        value={options.filter((i) => i.value?.toString() === val?.toString())}
      />
      {message ? <small className="text-danger">{message}</small> : null}
    </FormGroup>
  );
};

export default React.memo(FormSelect);
