import {
  fetchKios,
  addKios,
  updateStatusKios,
  editKios,
  updateKios,
  updateVerification,
} from "helpers/kios_helper";
import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  addKiosFailed,
  addKiosSuccess,
  editKiosFailed,
  editKiosSuccess,
  fetchKiosFailed,
  fetchKiosSuccess,
  handleChangeKios,
  updateKiosFailed,
  updateKiosSuccess,
  updateStatusKiosFailed,
  updateStatusKiosSuccess,
  updateVerificationFailed,
  updateVerificationSuccess,
} from "./actions";
import {
  ADD_KIOS,
  EDIT_KIOS,
  FETCH_KIOS,
  HANDLE_CHANGE,
  UPDATE_KIOS,
  UPDATE_STATUS_KIOS,
  UPDATE_VERIFICATION,
} from "./actionTypes";

function* getKiosData({ payload: { params } }) {
  try {
    const response = yield call(fetchKios, params);

    yield put(fetchKiosSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchKiosFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchKiosFailed(error.response.data));
    }
  }
}

function* fillKiosData({ payload: { name, value } }) {
  yield put(handleChangeKios(name, value));
}

function* addKiosData({ payload: { data } }) {
  try {
    const response = yield call(addKios, data);

    yield put(addKiosSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(addKiosFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(addKiosFailed(error.response.data));
    }
  }
}

function* updateKiosStatus({ payload: { data, id } }) {
  try {
    const response = yield call(updateStatusKios, data, id);

    yield put(updateStatusKiosSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateStatusKiosFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateStatusKiosFailed(error.response.data));
    }
  }
}

function* editKiosData({ payload: { id } }) {
  try {
    const response = yield call(editKios, id);

    yield put(editKiosSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(editKiosFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(editKiosFailed(error.response.data));
    }
  }
}

function* updateKiosData({ payload: { data, id } }) {
  try {
    const response = yield call(updateKios, data, id);

    yield put(updateKiosSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateKiosFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateKiosFailed(error.response.data));
    }
  }
}

function* updateVerificationData({ payload: { data, id } }) {
  try {
    const response = yield call(updateVerification, data, id);

    yield put(updateVerificationSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateVerificationFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateVerificationFailed(error.response.data));
    }
  }
}

function* KiosSaga() {
  yield takeEvery(FETCH_KIOS, getKiosData);
  yield takeEvery(ADD_KIOS, addKiosData);
  yield takeEvery(UPDATE_STATUS_KIOS, updateKiosStatus);
  yield takeEvery(EDIT_KIOS, editKiosData);
  yield takeEvery(UPDATE_KIOS, updateKiosData);
  yield takeEvery(UPDATE_VERIFICATION, updateVerificationData);
  yield take(HANDLE_CHANGE, fillKiosData);
}

export default KiosSaga;
