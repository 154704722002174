import {
  FETCH_BALANCE,
  FETCH_BALANCE_FAILED,
  FETCH_BALANCE_SUCCESS,
} from "./actionTypes";

export const fetchBalance = () => ({
  type: FETCH_BALANCE,
});

export const fetchBalanceSuccess = (data) => ({
  type: FETCH_BALANCE_SUCCESS,
  payload: data,
});

export const fetchBalanceFailed = (error) => ({
  type: FETCH_BALANCE_FAILED,
  payload: error,
});
