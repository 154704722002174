export const priceDecimal = (string, separator = ".") => {
  if (string !== null && string !== undefined) {
    string = string.toString().replace(".00", "");
    string += "";
    let x = string.split(separator);
    let x1 = x[0];
    let x2 = x.length > 1 ? separator + x[1] : "";
    let rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + separator + "$2");
    }

    return x1 + x2;
  } else {
    return "-";
  }
};

export const encodeQueryData = (data) => {
  let ret = [];

  for (let d in data) {
    let value = data[d];

    if (value !== "") {
      if (typeof value == "object") {
        value = data[d].join(",");
      }

      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value));
    }
  }

  return ret.join("&");
};

export const formatRupiah = (angka, prefix) => {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  let separator;

  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
};

export const addDashes = (str) => {
  // 0851 5888 5894
  const number = str.replace(/\D[^\.]/g, "");
  const first = number.slice(0, 4);
  const second = number.slice(4, 8);
  const third = number.slice(8, 13);

  return `${first}-${second}-${third}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isNegative = (num) => {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
};
