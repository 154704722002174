// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-item.active .page-link {
  background-color: #30a7ea !important;
  border-color: #30a7ea !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/style.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,gCAAgC,EAAA","sourcesContent":[".page-item.active .page-link {\n  background-color: #30a7ea !important;\n  border-color: #30a7ea !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
