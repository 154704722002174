import { useSelector } from "react-redux";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";

const columns = ({ onEdit, onChangeStatus }) => {
  const { page, perPage } = useSelector((s) => s.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (_, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "Kategori Produk",
      width: "200px",
      cell: (row) => (
        <span className="obj-el fw-bolder" data-tag="allowRowEvents">
          {row.product_sections?.name}
        </span>
      ),
    },
    {
      name: "Produk",
      width: "280px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.name}
        </span>
      ),
    },
    {
      name: "Produk Slug",
      width: "280px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.slug}
        </span>
      ),
    },
    {
      name: "Nomor Urut",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.orderNo}
        </span>
      ),
    },
    {
      name: "Status Produk",
      width: "150px",
      cell: (row) => (
        <span className="obj-el fw-bolder" data-tag="allowRowEvents">
          <Badge color={row.status ? "success" : "danger"} className="obj-el">
            {row.status === 1 ? "Aktif" : "Nonaktif"}
          </Badge>
        </span>
      ),
    },
    {
      name: "Action",
      width: "80px",
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a">
            <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => onEdit(row)}>Edit</DropdownItem>
            <DropdownItem onClick={() => onChangeStatus(row.id, row.status)}>
              {row.status === 1 ? "Nonaktifkan" : "Aktifkan"}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
};

columns.propTypes = {
  onEdit: PropTypes.func,
  onChangeStatus: PropTypes.func,
};

export default columns;
