import { fetchPromo, updatePromo, addPromo } from "helpers/promo_helper";
import { put, take, takeEvery, call } from "redux-saga/effects";
import {
  deletePayloadChange,
  fetchPromoFailed,
  fetchPromoSuccess,
  payloadChange,
  postPromoFailed,
  postPromoSuccess,
  updatePromoFailed,
  updatePromoSuccess,
} from "./actions";
import {
  DELETE_PAYLOAD_CHANGE,
  FETCH_PROMO,
  POST_PROMO,
  PROMO_HANDLE_CHANGE,
  UPDATE_PROMO,
} from "./actionTypes";

function* onChange({ payload: { name, value } }) {
  yield put(payloadChange(name, value));
}

function* onDeleteChange({ payload: { name } }) {
  yield put(deletePayloadChange(name));
}

function* getPromo({ payload: { params } }) {
  try {
    const response = yield call(fetchPromo, params);

    yield put(fetchPromoSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchPromoFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchPromoFailed(error.response.data));
    }
  }
}

function* editPromo({ payload: { id, data } }) {
  try {
    const response = yield call(updatePromo, id, data);

    yield put(updatePromoSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updatePromoFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updatePromoFailed(error.response.data));
    }
  }
}

function* postPromo({ payload: { data } }) {
  try {
    const response = yield call(addPromo, data);
    yield put(postPromoSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(postPromoFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(postPromoFailed(error.response.data));
    }
  }
}

function* PromoSaga() {
  yield takeEvery(FETCH_PROMO, getPromo);
  yield takeEvery(UPDATE_PROMO, editPromo);
  yield takeEvery(POST_PROMO, postPromo);
  yield take(PROMO_HANDLE_CHANGE, onChange);
  yield take(DELETE_PAYLOAD_CHANGE, onDeleteChange);
}

export default PromoSaga;
