import {
  EXPORT_TRANSACTION,
  EXPORT_TRANSACTION_CLEAN_UP,
  EXPORT_TRANSACTION_FAILED,
  EXPORT_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION,
  FETCH_TRANSACTION_FAILED,
  FETCH_TRANSACTION_SUCCESS,
  PUT_TRANSACTION,
  PUT_TRANSACTION_CLEAN_UP,
  PUT_TRANSACTION_FAILED,
  PUT_TRANSACTION_SUCCESS
} from "./actionTypes";

export const fetchTransaction = (params) => ({
  type: FETCH_TRANSACTION,
  payload: { params },
});

export const fetchTransactionSuccess = (data) => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: data,
});

export const fetchTransactionFailed = (error) => ({
  type: FETCH_TRANSACTION_FAILED,
  payload: error,
});

export const exportTransaction = (params) => ({
  type: EXPORT_TRANSACTION,
  payload: { params },
});

export const exportTransactionSuccess = (data) => ({
  type: EXPORT_TRANSACTION_SUCCESS,
  payload: data,
});

export const exportTransactionFailed = (error) => ({
  type: EXPORT_TRANSACTION_FAILED,
  payload: error,
});

export const exportTransactionCleanup = () => ({
  type: EXPORT_TRANSACTION_CLEAN_UP,
});

export const putTransaction = (data, id) => ({
  type: PUT_TRANSACTION,
  payload: { data, id },
});

export const putTransactionSuccess = (data) => ({
  type: PUT_TRANSACTION_SUCCESS,
  payload: data,
});

export const putTransactionFailed = (error) => ({
  type: PUT_TRANSACTION_FAILED,
  payload: error,
});

export const putTransactionCleanup = () => ({
  type: PUT_TRANSACTION_CLEAN_UP,
});