import React from "react";
import ReactPaginate from "react-paginate";
import { Row, Col } from "reactstrap";
import "./style.scss";

const Pagination = ({
  forcePage = 1,
  perPage = 10,
  totalRow = 0,
  pageCount = 1,
  onPageChange = () => {},
}) => {
  const toRow = (forcePage + 1) * perPage;

  return (
    <Row>
      <Col
        lg="auto"
        className="d-flex align-items-center mt-2 mt-md-0 justify-content-center justify-content-lg-start"
      >
        <div className="pl-1 pr-1">
          <small>
            Showing {totalRow ? forcePage * perPage + 1 : 0} to{" "}
            {toRow > totalRow ? totalRow : toRow} of {totalRow} entries
          </small>
        </div>
      </Col>
      <Col className="align-items-center">
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={onPageChange}
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={".."}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          activeClassName={"active"}
          pageClassName={"page-item"}
          nextLinkClassName={"page-link"}
          nextClassName={"page-item next"}
          previousClassName={"page-item prev"}
          previousLinkClassName={"page-link"}
          pageLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          containerClassName={
            "pagination react-paginate justify-content-center justify-content-lg-end my-2 pr-lg-1"
          }
        />
      </Col>
    </Row>
  );
};

export default Pagination;
