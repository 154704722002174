const {
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS,
  FETCH_BANKS_FAILED,
  FETCH_PROVINCES,
  FETCH_PROVINCES_SUCCESS,
  FETCH_PROVINCES_FAILED,
  FETCH_REGENCIES_SUCCESS,
  FETCH_REGENCIES_FAILED,
  FETCH_DISTRICTS,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILED,
  FETCH_REGENCIES,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  FETCH_PARAMS,
  FETCH_PARAMS_SUCCESS,
  FETCH_PARAMS_FAILED,
  UPLOAD_IMAGE_CLEANUP,
} = require("./actionTypes");

const initialState = {
  data: [],
  loading: false,
  message: "",
  messageTitle: "",
};

const Bank = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANKS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BANKS_SUCCESS:
      const data = [];
      action.payload.data.map((item) =>
        data.push({ label: item.name, value: item.id })
      );

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_BANKS_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    default:
      return state;
  }
};

export const Provinces = (
  state = {
    data: [],
    loading: false,
    message: "",
    messageTitle: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_PROVINCES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROVINCES_SUCCESS:
      const data = [];
      action.payload.data.map((item) =>
        data.push({ label: item.name, value: item.id })
      );

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_PROVINCES_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    default:
      return state;
  }
};

export const Regencies = (
  state = {
    data: [],
    loading: false,
    message: "Daftar kota tidak tersedia",
    messageTitle: "Data Kota",
  },
  action
) => {
  switch (action.type) {
    case FETCH_REGENCIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REGENCIES_SUCCESS:
      const data = [];
      action.payload.data.map((item) =>
        data.push({ label: item.name, value: item.id })
      );

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_REGENCIES_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    default:
      return state;
  }
};

export const Districts = (
  state = {
    data: [],
    loading: false,
    message: "Daftar kecamatan tidak tersedia",
    messageTitle: "Data Kecamatan",
  },
  action
) => {
  switch (action.type) {
    case FETCH_DISTRICTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DISTRICTS_SUCCESS:
      const data = [];
      action.payload.data.map((item) =>
        data.push({ label: item.name, value: item.id })
      );

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_DISTRICTS_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    default:
      return state;
  }
};

export const Upload = (
  state = {
    data: null,
    loading: false,
    message: "",
    messageTitle: "",
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      const { name } = action.payload;

      return {
        ...state,
        [name]: action.payload.data.url,
        loading: false,
      };
    case UPLOAD_IMAGE_FAILED:
      const { name: nameFailed } = action.payload;

      return {
        ...state,
        [nameFailed]: null,
        loading: false,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    case UPLOAD_IMAGE_CLEANUP:
      return {
        data: null,
        loading: false,
        message: "",
        messageTitle: "",
      };
    default:
      return state;
  }
};

export const Params = (
  state = {
    data: {
      category: [],
    },
    loading: false,
    message: "Gagal mengambil data params",
    messageTitle: "Data Params",
  },
  action
) => {
  switch (action.type) {
    case FETCH_PARAMS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARAMS_SUCCESS:
      const category = [];
      action.payload.data.category.map((item) =>
        category.push({ label: item.name, value: item.id })
      );

      return {
        ...state,
        data: {
          category,
        },
      };
    case FETCH_PARAMS_FAILED:
      return {
        ...state,
        loading: false,
        data: {
          category: [],
        },
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
      };
    default:
      return state;
  }
};

export default Bank;
