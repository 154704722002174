import React from "react";
import { Container, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTopup,
  updateStatusTopup,
  updateStatusTopupCleanUp,
  handleChange,
  exportTopup,
  exportTopupCleanUp,
  paramsCleanup,
} from "../../store/actions";
import DataTable from "react-data-table-component";
import Pagination from "../../components/Pagination";
import columns from "./columns";
import Filter from "./filter";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from "components/Common/SweetAlert";
import { ToastContainer, toast } from "react-toastify";
import CustomToast from "components/Common/CustomToast";
import ModalDetail from "./modalDetail";
import "./topup.scss";

const messageAlert = {
  approve: (
    <>
      Status pembayaran akan berubah menjadi <strong>berhasil</strong> setelah
      kamu mengkonfirmasi
    </>
  ),
  reject: "Permintaan pembatalan topup akan berhasil setelah dikonfirmasi",
};

const title = {
  approve: "Konfirmasi Pembayaran ?",
  reject: "Batalkan Request?",
};

const dummyData = {
  amount: 0,
  bankId: 0,
  sellerId: 0,
  status: 0,
  type: "Topup",
  adminFee: 0,
  createdAt: "2022-08-27T11:06:04.000Z",
  updatedAt: "2022-09-04T10:40:15.000Z",
  seller: {
    id: 1,
    firstName: "Lorem",
    lastName: "Ipsum",
    phone: "85158885894",
    accountNumber: "1122334455",
    accountName: "Lorem Ipsum",
    bankName: "Lorem",
    city: "Lorem Ipsum",
    currentBalance: 0,
    profilePicture: null,
  },
};

const TopUp = (p) => {
  document.title = "Top Up | Pulsadong";

  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = React.useState(null);
  const [dataRow, setDataRow] = React.useState(null);
  const [modalDetail, setModalDetail] = React.useState(false);

  const { data, loading, message, total } = useSelector((state) => ({
    loading: state.Topup.loading,
    data: state.Topup.data,
    message: state.Topup.message,
    total: state.Topup.total,
  }));
  const {
    data: dataUpdate,
    loading: loadingUpdate,
    message: messageUpdate,
    messageTitle: messageTItleUpdate,
  } = useSelector((state) => state.UpdateStatusTopup);
  const params = useSelector((state) => state.Params);
  const {
    data: dataExport,
    loading: loadingExport,
    message: messageExport,
    messageTitle: messageTitleExport,
  } = useSelector((state) => state.ExportExcel);

  const downloadRef = React.useRef();

  React.useEffect(() => {
    return () => {
      dispatch(paramsCleanup());
    };
  }, []);

  React.useEffect(() => {
    dispatch(fetchTopup(params));
  }, [params]);

  React.useEffect(() => {
    if (dataUpdate) {
      toast(
        <CustomToast
          title={messageTItleUpdate}
          type="success"
          desc={messageUpdate}
        />
      );
      setShowAlert(null);

      dispatch(updateStatusTopupCleanUp());
      dispatch(fetchTopup(params));
    }
  }, [dataUpdate]);

  React.useEffect(() => {
    if (dataExport) {
      downloadRef.current.src = dataExport.url;

      toast(
        <CustomToast
          title={messageTitleExport}
          type="success"
          desc={messageExport}
        />
      );
      dispatch(exportTopupCleanUp());
    }
  }, [dataExport]);

  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onShowConfirm = (status, id, type) => {
    setShowAlert(type);
    setDataRow({ status, id });
  };

  const onCancelConfirm = () => setShowAlert(null);

  const onSubmitConfirm = () => {
    const formData = {
      status: dataRow.status,
    };

    dispatch(updateStatusTopup(formData, dataRow?.id));
  };

  const onExportExcel = (type) => {
    const newParams = {
      ...params,
      type,
    };

    dispatch(exportTopup(newParams));
  };

  const onShowDetail = React.useCallback((row) => {
    setModalDetail(true);
    setDataRow(row);
  }, []);

  const onCloseDetail = React.useCallback(() => {
    setModalDetail(false);
    setDataRow(null);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" id="topup-page">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Topup Saldo" />
          <Card>
            <CardBody className={loading ? "sekeleton active" : ""}>
              <Filter onExport={onExportExcel} loadingExport={loadingExport} />
              <DataTable
                columns={columns({ onShowConfirm, onShowDetail })}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
                noHeader
                sortServer
                paginationServer
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SweetAlert
        show={showAlert != null}
        onCancel={onCancelConfirm}
        onSubmit={onSubmitConfirm}
        title={title[showAlert]}
        message={messageAlert[showAlert]}
        loading={loadingUpdate}
        submitLabel="Konfirmasi"
        cancelLabel="Batal"
      />
      <ToastContainer position="bottom-right" progressClassName="progressbar" />
      <iframe ref={downloadRef} />
      <ModalDetail
        data={dataRow}
        isOpen={modalDetail}
        onClosed={onCloseDetail}
      />
    </React.Fragment>
  );
};

export default TopUp;
