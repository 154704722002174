// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topup-page .rdt_TableHeadRow {
  background: #f8f9fa;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left; }

.topup-page .icon-wrapper {
  background-color: #e5f6fc;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center; }

#modal-detail-topup .initials-avatar {
  width: 80px !important;
  height: 80px !important;
  border-radius: 6px;
  font-size: 38px; }
`, "",{"version":3,"sources":["webpack://./src/pages/TopUp/topup.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB,EAAA;;AARpB;EAYI,yBAAyB;EACzB,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAIvB;EAEI,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe,EAAA","sourcesContent":[".topup-page {\n  .rdt_TableHeadRow {\n    background: #f8f9fa;\n    font-family: Poppins;\n    font-size: 13px;\n    font-weight: 600;\n    line-height: 20px;\n    letter-spacing: 0px;\n    text-align: left;\n  }\n\n  .icon-wrapper {\n    background-color: #e5f6fc;\n    border-radius: 100px;\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n#modal-detail-topup {\n  .initials-avatar {\n    width: 80px !important;\n    height: 80px !important;\n    border-radius: 6px;\n    font-size: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
