const { encodeQueryData } = require("helper");
const { FETCH_ACADEMY_LIST } = require("./constant");
const { get, post, put } = require("./api_helper");

const fetchAcademyBanner = (params) =>
  get(
    `${FETCH_ACADEMY_LIST}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );
const postAcademyBanner = (data) => post(FETCH_ACADEMY_LIST, data);
const putAcademyBanner = (data, id) => put(`${FETCH_ACADEMY_LIST}/${id}`, data);

export { fetchAcademyBanner, postAcademyBanner, putAcademyBanner };
