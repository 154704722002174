import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from "./filter";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "components/Pagination";
import columns from "./columns";
import { fetchAcademyBanner } from "store/actions";
import ModalForm from "./modalForm";
import SweetAlert from "components/Common/SweetAlert";
import { putAcademyBanner } from "helpers/academy_helper";
import { ToastContainer, toast } from "react-toastify";
import CustomToast from "components/Common/CustomToast";

const dummyData = {
  slug: "cara-topup",
  title: "Cara Topup",
  url: "https://firebasestorage.googleapis.com/v0/b/pulsadong.appspot.com/o/WhatsApp%20Image%202023-01-18%20at%2023_1674060970551.55?alt=media&token=73fde944-a502-4e43-92ee-aa07cb92263e",
  detail: "Lorem ipsum dolor sit amet amet",
  order: 1,
  status: 1,
  createdAt: "2023-03-01T13:26:20.000Z",
  updatedAt: "2023-03-01T13:27:20.000Z",
  statusString: "aktif",
};

const alertTitle = {
  1: "Aktifkan banner",
  0: "Nonaktikan banner",
};

const Academy = () => {
  const params = useSelector((s) => s.Params);
  const {
    data: bannerList,
    loading: bannerLoading,
    total,
  } = useSelector((s) => s.AcademyBanner);
  const dispatch = useDispatch();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openConfirmStatus, setOpenConfirmStatus] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  //meta title
  document.title = "Pulsadong | Pulsadong Akademi";

  useEffect(() => {
    getAcademyBannerList();
  }, [params]);

  const getAcademyBannerList = useCallback(() => {
    dispatch(fetchAcademyBanner(params));
  }, [params]);

  const onOpenForm = () => setIsOpenForm(true);

  const onCloseForm = () => {
    setFormData(null);
    setIsOpenForm(false);
  };

  const onPageChange = () => console.log("page clicked");

  const onOpenUpdateStatus = (row) => {
    setFormData({ ...row, status: row.status ? 0 : 1 });
    setOpenConfirmStatus(true);
  };

  const onSubmitUpdateStatus = async () => {
    setLoadingUpdateStatus(true);
    try {
      const putResponse = await putAcademyBanner(formData, formData?.id);
      onCancelUpdatestatus();
      getAcademyBannerList();
      toast(
        <CustomToast
          title={putResponse?.messageTitle}
          desc={putResponse?.message}
          type={"success"}
        />
      );
    } catch (e) {
      setLoadingUpdateStatus(false);
      toast(
        <CustomToast
          title={e?.response?.data?.messageTitle}
          desc={e?.response?.data?.message}
          type={"success"}
        />
      );
      throw e;
    }
  };

  const onCancelUpdatestatus = () => {
    setFormData(null);
    setOpenConfirmStatus(false);
    setLoadingUpdateStatus(false);
  };

  const onOpenEdit = (row) => {
    setFormData(row);
    setIsOpenForm(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Pulsadong Akademi" />
          <Card>
            <CardBody className={bannerLoading ? "sekeleton active" : ""}>
              <Filter onOpenForm={onOpenForm} />
              <DataTable
                noHeader
                sortServer
                paginationServer
                columns={columns({ onOpenEdit, onOpenUpdateStatus })}
                data={
                  bannerLoading
                    ? Array.from({ length: 5 }, () => dummyData)
                    : bannerList
                }
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ModalForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        onFetch={getAcademyBannerList}
        dataEdit={formData}
      />
      <SweetAlert
        show={openConfirmStatus}
        title={alertTitle[formData?.status]}
        message="Apa kamu yakin?"
        type="confirm"
        onSubmit={onSubmitUpdateStatus}
        onCancel={onCancelUpdatestatus}
        loading={loadingUpdateStatus}
      />
      <ToastContainer
        position="bottom-right"
        progressClassName="progressbar"
        autoClose={2000}
      />
    </React.Fragment>
  );
};

export default Academy;
