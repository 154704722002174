// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sweet-alert i.mdi {
  color: #fe642f;
  font-size: 80px; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/custom/components/_sweetalert.scss"],"names":[],"mappings":"AAAA;EAGM,cAAc;EACd,eAAe,EAAA","sourcesContent":[".sweet-alert {\n  i {\n    &.mdi {\n      color: #fe642f;\n      font-size: 80px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
