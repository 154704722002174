const {
  FETCH_ACADEMY_BANNER,
  FETCH_ACADEMY_BANNER_SUCCESS,
  FETCH_ACADEMY_BANNER_FAILED,
} = require("./actionTypes");

const initState = {
  loading: false,
  data: [],
  total: 0,
};

const AcademyBanner = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ACADEMY_BANNER:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACADEMY_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.total,
      };
    case FETCH_ACADEMY_BANNER_FAILED:
      return {
        ...state,
        loading: false,
        data: { ...initState.data },
      };
    default:
      return state;
  }
};

export default AcademyBanner;
