import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { handleChange } from "store/actions";
import { Link } from "react-router-dom";

const Filter = () => {
  const dispatch = useDispatch();

  const onSearch = React.useRef(
    debounce((e) => {
      dispatch(handleChange("q", e.target.value));
    }, 500)
  ).current;

  return (
    <Row className="justify-content-between">
      <Col xs="12" md="auto">
        <Input
          placeholder="Search..."
          className="mb-xs-3 mb-md-0 rounded-pill ps-3"
          onChange={onSearch}
        />
      </Col>
      <Col xs="12" md="auto">
        <Link to="/kios/add">
          <Button color="warning">Kios Baru</Button>
        </Link>
      </Col>
    </Row>
  );
};

export default Filter;
