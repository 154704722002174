const {
  FETCH_DAFTAR_HARGA,
  FETCH_DAFTAR_HARGA_SUCCESS,
  FETCH_DAFTAR_HARGA_FAILED,
  UPDATE_HARGA_JUAL,
  UPDATE_HARGA_JUAL_SUCCESS,
  UPDATE_HARGA_JUAL_FAILED,
  UPDATE_HARGA_JUAL_CLEANUP,
  UPDATE_STATUS_HARGA_JUAL,
  UPDATE_STATUS_HARGA_JUAL_SUCCESS,
  UPDATE_STATUS_HARGA_JUAL_FAILED,
  UPDATE_STATUS_HARGA_JUAL_CLEANUP,
} = require("./actionTypes");

const initState = {
  loading: false,
  data: [],
  total: 0,
  message: null,
};

const postState = {
  loading: false,
  data: null,
  message: "",
  messageTitle: "",
  success: null,
};

const DaftarHarga = (state = initState, action) => {
  switch (action.type) {
    case FETCH_DAFTAR_HARGA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DAFTAR_HARGA_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        loading: false,
      };
    case FETCH_DAFTAR_HARGA_FAILED:
      return {
        ...state,
        data: [],
        total: 0,
        loading: false,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export const UpdateHargaJual = (state = postState, action) => {
  switch (action.type) {
    case UPDATE_HARGA_JUAL:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_HARGA_JUAL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: action.payload.success,
      };
    case UPDATE_HARGA_JUAL_FAILED:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: action.payload.success,
      };
    case UPDATE_HARGA_JUAL_CLEANUP:
      return postState;
    default: {
      return state;
    }
  }
};

export const UpdateStatusHargaJual = (state = postState, action) => {
  switch (action.type) {
    case UPDATE_STATUS_HARGA_JUAL:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_STATUS_HARGA_JUAL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: action.payload.success,
      };
    case UPDATE_STATUS_HARGA_JUAL_FAILED:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: action.payload.success,
      };
    case UPDATE_STATUS_HARGA_JUAL_CLEANUP:
      return postState;
    default: {
      return state;
    }
  }
};

export default DaftarHarga;
