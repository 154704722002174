import React from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Label, Input } from "reactstrap";
import { handleChangeKios } from "store/actions";

const KiosBank = () => {
  const dispatch = useDispatch();
  const {
    data: dataBanks,
    loading: loadingBanks,
    message: messageBanks,
    messageTitle: messageTitleBanks,
  } = useSelector((s) => s.Bank);
  const { data, success } = useSelector((s) => s.AddKios);
  const formdata = useSelector((s) => s.FormKios);

  const onChange = (e, val) => {
    const name = e.target?.name || e;
    const value = e.target?.value || val;

    dispatch(handleChangeKios(name, value));
  };

  return (
    <Form aria-autocomplete="none" autoComplete="off">
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-namecard-input11">Bank</Label>
            <Select
              onChange={(e) => onChange("bank", e.value)}
              options={dataBanks}
              classNamePrefix="select2-selection"
              isLoading={loadingBanks}
              noOptionsMessage={() => `${messageTitleBanks}: ${messageBanks}`}
              placeholder="Select Your Bank"
              value={dataBanks.filter((i) => i.value == formdata.bank)}
            />
            {data && !success && (
              <small className="text-danger">{data["bank"][0]}</small>
            )}
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label>Cabang Bank (Opsional)</Label>
            <Input
              type="text"
              name="bankBranch"
              onChange={onChange}
              placeholder="Enter Your Bank Branch"
              value={formdata.bankBranch}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-cardno-input12">Nomor Rekening</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Enter Your Account Number"
              name="accountNumber"
              onChange={onChange}
              value={formdata.accountNumber}
            />
            {data && !success && (
              <small className="text-danger">{data["accountNumber"][0]}</small>
            )}
          </div>
        </Col>

        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-card-verification-input0">
              Nama Pemilik Rekening
            </Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Enter Your Account Holder Name"
              name="accountName"
              onChange={onChange}
              value={formdata.accountName}
            />
            {data && !success && (
              <small className="text-danger">{data["accountName"][0]}</small>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default KiosBank;
