import React from "react";
import { FormGroup, Label, Card, Row, Col } from "reactstrap";
import Dropzone from "react-dropzone";
import { formatBytes } from "helper";

const FormUpload = ({ getValue = () => {}, value = null, message = null }) => {
  const [selectedFile, setSelectedFile] = React.useState(value);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const uploadBanner = React.useCallback((files) => {
    if (files.length > 0) {
      const file = files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB <= 2) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile((prev) => ({
            ...prev,
            banner: { ...prev?.banner, preview: reader.result },
          }));
        };
        reader.readAsDataURL(file);
        getValue(file);
        setSelectedFile((prev) => ({
          ...prev,
          banner: {
            files,
            filename: files[0].name,
            size: formatBytes(files[0].size),
          },
        }));
        setErrorMsg(null);
      } else {
        setErrorMsg("Ukuran file melebihi maksimum 2 MB.");
      }
    } else {
      setErrorMsg("Banner harus berupa file gambar!");
    }
  }, []);

  return (
    <FormGroup>
      <Label>Upload Banner</Label>
      <Dropzone
        onDrop={(u) => uploadBanner(u)}
        multiple={false}
        accept="image/*"
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h6>Tarik file kesini atau klik untuk memulai upload</h6>
            </div>
          </div>
        )}
      </Dropzone>
      {errorMsg && <small className="text-danger">{errorMsg}</small>}
      {selectedFile && (
        <div className="dropzone-previews mt-3" id="file-previews">
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    className="thumbnail-preview avatar-md rounded bg-light avatar-fit"
                    src={
                      typeof selectedFile === "string"
                        ? selectedFile
                        : selectedFile?.banner?.preview
                    }
                  />
                </Col>
                <Col>
                  <Label to="#" className="text-muted font-weight-bold">
                    {selectedFile?.banner?.filename}
                  </Label>
                  <p className="mb-0">
                    <strong>{selectedFile?.banner?.size}</strong>
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
      {message ? <small className="text-danger">{message}</small> : null}
    </FormGroup>
  );
};

export default React.memo(FormUpload);
