import {
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILED,
  FETCH_TOP_SELLING,
  FETCH_TOP_SELLING_SUCCESS,
  FETCH_TOP_SELLING_FAILED,
  FETCH_TRANSACITON_ANALYTICS,
  FETCH_TRANSACITON_ANALYTICS_SUCCESS,
  FETCH_TRANSACITON_ANALYTICS_FAILED,
  FETCH_LATEST_TOPUP,
  FETCH_LATEST_TOPUP_SUCCESS,
  FETCH_LATEST_TOPUP_FAILED,
} from "./actionTypes";

export const fetchSummary = () => ({
  type: FETCH_SUMMARY,
});

export const fetchSummarySuccess = (data) => ({
  type: FETCH_SUMMARY_SUCCESS,
  payload: data,
});

export const fetchSummaryFailed = (error) => ({
  type: FETCH_SUMMARY_FAILED,
  payload: error,
});

export const fetchTopSelling = () => ({
  type: FETCH_TOP_SELLING,
});

export const fetchTopSellingSuccess = (data) => ({
  type: FETCH_TOP_SELLING_SUCCESS,
  payload: data,
});

export const fetchTopSellingFailed = (error) => ({
  type: FETCH_TOP_SELLING_FAILED,
  payload: error,
});

export const fetchTransactionAnalytics = () => ({
  type: FETCH_TRANSACITON_ANALYTICS,
});

export const fetchTransactionAnalyticsSuccess = (data) => ({
  type: FETCH_TRANSACITON_ANALYTICS_SUCCESS,
  payload: data,
});

export const fetchTransactionAnalyticsFailed = (error) => ({
  type: FETCH_TRANSACITON_ANALYTICS_FAILED,
  payload: error,
});

export const fetchLatestTopup = (params) => ({
  type: FETCH_LATEST_TOPUP,
  payload: { params },
});

export const fetchLatestTopupSuccess = (data) => ({
  type: FETCH_LATEST_TOPUP_SUCCESS,
  payload: data,
});

export const fetchLatestTopupFailed = (error) => ({
  type: FETCH_LATEST_TOPUP_FAILED,
  payload: error,
});
