const {
  FETCH_BALANCE,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILED,
} = require("./actionTypes");

const initState = {
  loading: false,
  data: {
    balanceUtama: 0,
    balanceKios: 0,
  },
};
const Balance = (state = initState, action) => {
  switch (action.type) {
    case FETCH_BALANCE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case FETCH_BALANCE_FAILED:
      return {
        ...state,
        loading: false,
        data: { ...initState.data },
      };
    default:
      return state;
  }
};

export default Balance;
