import { priceDecimal } from "helper";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const status = {
  0: <Badge color="warning">Pending</Badge>,
  1: <Badge color="danger">Gagal</Badge>,
  2: <Badge color="success">Berhasil</Badge>
};

const columns = ({onShowConfirm}) => {
  const { page, perPage } = useSelector((state) => state.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (__, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "Request ID",
      width: "180px",
      cell: (row) => (
        <span className="obj-el fw-bold" data-tag="allowRowEvents">
          #{row.orderId}
        </span>
      ),
    },
    {
      name: "Kios Name",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.seller?.kiosName}
        </span>
      ),
    },
    {
      name: "Tanggal Order",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {moment(row.createdAt).format("D MMM Y, HH:mm")}
        </span>
      ),
    },
    {
      name: "Source",
      width: "100px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {!row.source ? "Mobile" : "Web"}
        </span>
      ),
    },
    {
      name: "No Pelanggan",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.customerNo}
        </span>
      ),
    },
    {
      name: "Produk",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.product?.productName}
        </span>
      ),
    },
    {
      name: "Nilai Produk",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp {priceDecimal(row.capitalPrice)}
        </span>
      ),
    },
    {
      name: "Modal",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp {priceDecimal(row.profit)}
        </span>
      ),
    },
    {
      name: "Penjualan",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          Rp {priceDecimal(row.sellingPrice)}
        </span>
      ),
    },
    {
      name: "Status Transaksi",
      width: "180px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {status[row.status]}
        </span>
      ),
    },
    {
      name: "Action",
      sortable: false,
      minWidth: 43,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a">
            <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem>Detail Transaksi</DropdownItem>
            <DropdownItem>Detail Kios</DropdownItem>
            {row.status === 1 ? <DropdownItem onClick={() => onShowConfirm(row.id, 1)}>Retry Transaksi</DropdownItem> : null}
            {row.status === 0 ? <DropdownItem onClick={() => onShowConfirm(row.id, 1)}>Confirm Transaksi</DropdownItem> : null}
            {row.status === 0 || row.status === 2 ? <DropdownItem onClick={() => onShowConfirm(row.id, 1)}>Batalkan Transaksi</DropdownItem> : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
};

export default columns;
