// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-fit {
  object-fit: contain;
  object-position: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/Promo/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB,EAAA","sourcesContent":[".avatar-fit {\n  object-fit: contain;\n  object-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
