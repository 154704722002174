import React from "react";
import { Modal, ModalHeader } from "reactstrap";

const ModalComp = ({
  isOpen = false,
  toggle = () => null,
  size = "sm",
  title = "",
  children,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      {children}
    </Modal>
  );
};

export default React.memo(ModalComp);
