import React from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Filter as FilterIcon, Refresh } from "iconsax-react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchDaftarHarga, handleChange } from "store/actions";

const Filter = () => {
  const params = useSelector((s) => s.Params);
  const dispatch = useDispatch();

  const onSearch = React.useRef(
    debounce((e) => {
      dispatch(handleChange("search", e.target.value));
    }, 500)
  ).current;

  const onSync = () => {
    dispatch(fetchDaftarHarga(params));
  };

  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <Button color="warning" className="rounded-circle px-2 py-2" size="md">
          <FilterIcon color="#FFFFFF" size={18} type="linear" />
        </Button>
      </Col>
      <Col xs="auto">
        <Input
          type="search"
          name="search"
          className="rounded-pill ps-3"
          placeholder="Search..."
          onChange={onSearch}
        />
      </Col>
      <Col>
        <div className="d-flex justify-content-end">
          <Button
            color="warning"
            className="rounded-circle px-2 py-2"
            title="Sync harga"
            onClick={onSync}
          >
            <Refresh color="#FFFFFF" size={18} />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Filter;
