const {
  PROMO_HANDLE_CHANGE,
  PAYLOAD_CLEANUP,
  FETCH_PROMO,
  FETCH_PROMO_SUCCESS,
  FETCH_PROMO_FAILED,
  UPDATE_PROMO,
  UPDATE_PROMO_SUCCESS,
  UPDATE_PROMO_FAILED,
  UPDATE_PROMO_CLEANUP,
  DELETE_PAYLOAD_CHANGE,
  POST_PROMO,
  POST_PROMO_SUCCESS,
  POST_PROMO_FAILED,
} = require("./actionTypes");

const initPayload = {
  title: "",
  categoryId: 1,
  status: 1,
  isHomepage: 0,
  order: 1,
  url: "",
};

const initPromo = {
  loading: false,
  data: [],
  total: 0,
};

const initPost = {
  loading: false,
  data: null,
  success: false,
  message: null,
  messageTitle: null,
};

const Promo = (state = initPayload, action) => {
  switch (action.type) {
    case PROMO_HANDLE_CHANGE:
      const name = action.payload.name;
      const value = action.payload.value;

      return {
        ...state,
        [name]: value,
      };

    case DELETE_PAYLOAD_CHANGE:
      delete state[action.payload.name];

      return state;

    case PAYLOAD_CLEANUP:
      return initPayload;

    default:
      return state;
  }
};

export const GetPromo = (state = initPromo, action) => {
  switch (action.type) {
    case FETCH_PROMO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROMO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.total,
      };
    case FETCH_PROMO_FAILED:
      return initPromo;
    default:
      return state;
  }
};

export const UpdatePromo = (state = initPost, action) => {
  switch (action.type) {
    case UPDATE_PROMO:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROMO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_PROMO_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_PROMO_CLEANUP:
      return initPost;
    default:
      return state;
  }
};

export const PostPromo = (state = initPost, action) => {
  switch (action.type) {
    case POST_PROMO:
      return {
        ...state,
        loading: true,
      };
    case POST_PROMO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case POST_PROMO_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        success: action.payload.success,
      };
    case UPDATE_PROMO_CLEANUP:
      return initPost;
    default:
      return state;
  }
};

export default Promo;
