export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILED = "FETCH_TRANSACTION_FAILED";
export const EXPORT_TRANSACTION = "EXPORT_TRANSACTION";
export const EXPORT_TRANSACTION_SUCCESS = "EXPORT_TRANSACTION_SUCCESS";
export const EXPORT_TRANSACTION_FAILED = "EXPORT_TRANSACTION_FAILED";
export const EXPORT_TRANSACTION_CLEAN_UP = "EXPORT_TRANSACTION_CLEAN_UP";
export const PUT_TRANSACTION = "PUT_TRANSACTION";
export const PUT_TRANSACTION_SUCCESS = "PUT_TRANSACTION_SUCCESS";
export const PUT_TRANSACTION_FAILED = "PUT_TRANSACTION_FAILED";
export const PUT_TRANSACTION_CLEAN_UP = "PUT_TRANSACTION_CLEAN_UP";
