const { encodeQueryData } = require("helper");
const { get, patch } = require("./api_helper");
const {
  FETCH_PRODUCT_LIST,
  FETCH_CATEGORY_LIST,
  UPDATE_PRODUCT_LIST,
  UPDATE_STATUS_PRODUCT_LIST,
} = require("./constant");

const fetchProductList = (params) =>
  get(
    `${FETCH_PRODUCT_LIST}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const fetchCategoryList = () => get(`${FETCH_CATEGORY_LIST}`);

const updateProductList = (data, id) =>
  patch(`${UPDATE_PRODUCT_LIST}/${id}`, data);

const updateStatusProductList = (data, id) =>
  patch(`${UPDATE_STATUS_PRODUCT_LIST}/${id}`, data);

export {
  fetchProductList,
  fetchCategoryList,
  updateProductList,
  updateStatusProductList,
};
