/** Get Dashboard Chart data */
export const FETCH_SUMMARY = "FETCH_SUMMARY";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAILED = "FETCH_SUMMARY_FAILED";
export const FETCH_TOP_SELLING = "FETCH_TOP_SELLING";
export const FETCH_TOP_SELLING_SUCCESS = "FETCH_TOP_SELLING_SUCCESS";
export const FETCH_TOP_SELLING_FAILED = "FETCH_TOP_SELLING_FAILED";
export const FETCH_TRANSACITON_ANALYTICS = "FETCH_TRANSACITON_ANALYTICS";
export const FETCH_TRANSACITON_ANALYTICS_SUCCESS =
  "FETCH_TRANSACITON_ANALYTICS_SUCCESS";
export const FETCH_TRANSACITON_ANALYTICS_FAILED =
  "FETCH_TRANSACITON_ANALYTICS_FAILED";
export const FETCH_LATEST_TOPUP = "FETCH_LATEST_TOPUP";
export const FETCH_LATEST_TOPUP_SUCCESS = "FETCH_LATEST_TOPUP_SUCCESS";
export const FETCH_LATEST_TOPUP_FAILED = "FETCH_LATEST_TOPUP_FAILED";
