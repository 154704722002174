export const PROMO_HANDLE_CHANGE = "PROMO_HANDLE_CHANGE";
export const PAYLOAD_CLEANUP = "PAYLOAD_CLEANUP";
export const FETCH_PROMO = "FETCH_PROMO";
export const FETCH_PROMO_SUCCESS = "FETCH_PROMO_SUCCESS";
export const FETCH_PROMO_FAILED = "FETCH_PROMO_FAILED";
export const UPDATE_PROMO = "UPDATE_PROMO";
export const UPDATE_PROMO_SUCCESS = "UPDATE_PROMO_SUCCESS";
export const UPDATE_PROMO_FAILED = "UPDATE_PROMO_FAILED";
export const UPDATE_PROMO_CLEANUP = "UPDATE_PROMO_CLEANUP";
export const DELETE_PAYLOAD_CHANGE = "DELETE_PAYLOAD_CHAGE";
export const POST_PROMO = "POST_PROMO";
export const POST_PROMO_SUCCESS = "POST_PROMO_SUCCESS";
export const POST_PROMO_FAILED = "POST_PROMO_FAILED";
