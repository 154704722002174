const {
  fetchDaftarHarga,
  updateHargaJual,
  updateStatusHargaJual,
} = require("helpers/daftar_harga_helper");
const { call, put, takeEvery } = require("redux-saga/effects");
const {
  fetchDaftarHargaSuccess,
  fetchDaftarHargaFailed,
  updateHargaJualSuccess,
  updateHargaJualFailed,
  updateStatusHargaJualSuccess,
  updateStatusHargaJualFailed,
} = require("./actions");
const {
  FETCH_DAFTAR_HARGA,
  UPDATE_HARGA_JUAL,
  UPDATE_STATUS_HARGA_JUAL,
} = require("./actionTypes");

function* getDaftarHargaData({ payload: { params } }) {
  try {
    const response = yield call(fetchDaftarHarga, params);

    yield put(fetchDaftarHargaSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchDaftarHargaFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchDaftarHargaFailed(error.response.data));
    }
  }
}

function* updateHargaJualData({ payload: { data, id } }) {
  try {
    const response = yield call(updateHargaJual, data, id);

    yield put(updateHargaJualSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateHargaJualFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateHargaJualFailed(error.response.data));
    }
  }
}

function* updateStatusHargaJualData({ payload: { data, id } }) {
  try {
    const response = yield call(updateStatusHargaJual, data, id);

    yield put(updateStatusHargaJualSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(updateStatusHargaJualFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(updateStatusHargaJualFailed(error.response.data));
    }
  }
}

function* DaftarHargaSaga() {
  yield takeEvery(FETCH_DAFTAR_HARGA, getDaftarHargaData);
  yield takeEvery(UPDATE_HARGA_JUAL, updateHargaJualData);
  yield takeEvery(UPDATE_STATUS_HARGA_JUAL, updateStatusHargaJualData);
}

export default DaftarHargaSaga;
