import { encodeQueryData } from "helper";

const { post, get, put } = require("./api_helper");
const { FETCH_PROMO_URL } = require("./constant");

const fetchPromo = (params) =>
  get(
    `${FETCH_PROMO_URL}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const updatePromo = (id, data) => put(`${FETCH_PROMO_URL}/${id}`, data);

const addPromo = (data) => post(`${FETCH_PROMO_URL}`, data);

export { fetchPromo, updatePromo, addPromo };
