import { get, patch } from "./api_helper";
import {
  FETCH_DAFTAR_HARGA,
  UPDATE_HARGA_JUAL,
  UPDATE_STATUS_HARGA_JUAL,
} from "./constant";
import { encodeQueryData } from "helper";

const fetchDaftarHarga = (params = {}) =>
  get(
    `${FETCH_DAFTAR_HARGA}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const updateHargaJual = (data, id) => patch(`${UPDATE_HARGA_JUAL}/${id}`, data);
const updateStatusHargaJual = (data, id) =>
  patch(`${UPDATE_STATUS_HARGA_JUAL}/${id}`, data);

export { fetchDaftarHarga, updateHargaJual, updateStatusHargaJual };
