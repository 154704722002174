import { HANDLE_CHANGE, PARAMS_CLEANUP } from "./actionTypes";

const initState = {
  page: 1,
  perPage: 10,
  q: "",
};

const Params = (state = initState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      const name = action.payload.name;
      const value = action.payload.value;

      return {
        ...state,
        [name]: value,
      };
    case PARAMS_CLEANUP:
      return initState;
    default:
      return state;
  }
};

export default Params;
