import {
  FETCH_BANKS,
  FETCH_BANKS_FAILED,
  FETCH_BANKS_SUCCESS,
  FETCH_DISTRICTS,
  FETCH_PROVINCES,
  FETCH_PROVINCES_FAILED,
  FETCH_PROVINCES_SUCCESS,
  FETCH_REGENCIES,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILED,
  FETCH_REGENCIES_FAILED,
  FETCH_REGENCIES_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  FETCH_PARAMS,
  FETCH_PARAMS_SUCCESS,
  FETCH_PARAMS_FAILED,
  UPLOAD_IMAGE_CLEANUP,
} from "./actionTypes";

export const fetchBanks = (params) => ({
  type: FETCH_BANKS,
  payload: { params },
});

export const fetchBanksSuccess = (data) => ({
  type: FETCH_BANKS_SUCCESS,
  payload: data,
});

export const fetchBanksFailed = (error) => ({
  type: FETCH_BANKS_FAILED,
  payload: error,
});

export const fetchProvinces = (params) => ({
  type: FETCH_PROVINCES,
  payload: { params },
});

export const fetchProvincesSuccess = (data) => ({
  type: FETCH_PROVINCES_SUCCESS,
  payload: data,
});

export const fetchProvincesFailed = (error) => ({
  type: FETCH_PROVINCES_FAILED,
  payload: error,
});

export const fetchRegencies = (params, provinceId) => ({
  type: FETCH_REGENCIES,
  payload: { params, provinceId },
});

export const fetchRegenciesSuccess = (data) => ({
  type: FETCH_REGENCIES_SUCCESS,
  payload: data,
});

export const fetchRegenciesFailed = (error) => ({
  type: FETCH_REGENCIES_FAILED,
  payload: error,
});

export const fetchDistricts = (params, regencyId) => ({
  type: FETCH_DISTRICTS,
  payload: { params, regencyId },
});

export const fetchDistrictsSuccess = (data) => ({
  type: FETCH_DISTRICTS_SUCCESS,
  payload: data,
});

export const fetchDistrictsFailed = (error) => ({
  type: FETCH_DISTRICTS_FAILED,
  payload: error,
});

export const uploadImage = (data, config, name) => ({
  type: UPLOAD_IMAGE,
  payload: { data, config, name },
});

export const uploadImageSuccess = (data) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: data,
});

export const uploadImageFailed = (error) => ({
  type: UPLOAD_IMAGE_FAILED,
  payload: error,
});

export const uploadImageCleanUp = () => ({
  type: UPLOAD_IMAGE_CLEANUP,
});

export const fetchParams = () => ({
  type: FETCH_PARAMS,
});

export const fetchParamsSuccess = (data) => ({
  type: FETCH_PARAMS_SUCCESS,
  payload: data,
});

export const fetchParamsFailed = (error) => ({
  type: FETCH_PARAMS_FAILED,
  payload: error,
});
