import React from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Filter as FilterIcon } from "iconsax-react";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { handleChange } from "store/actions";

const Filter = () => {
  const dispatch = useDispatch();

  const onSearch = React.useRef(
    debounce((e) => {
      dispatch(handleChange("search", e.target.value));
    }, 500)
  ).current;

  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <Button color="warning" className="rounded-circle px-2 py-2" size="md">
          <FilterIcon color="#FFFFFF" size={18} type="linear" />
        </Button>
      </Col>
      <Col xs="auto">
        <Input
          type="search"
          name="search"
          className="rounded-pill ps-3"
          placeholder="Search..."
          onChange={onSearch}
        />
      </Col>
    </Row>
  );
};

export default Filter;
