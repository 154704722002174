import { TickSquare, CloseSquare } from "iconsax-react";
import React from "react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const Columns = ({ toggle = () => null, toggleConfirm = () => null }) => {
  return [
    {
      name: "#",
      width: "80px",
      center: true,
      cell: (_, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {1 * 10 - 10 + (index + 1)}
        </span>
      ),
    },
    {
      name: "Promo",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row?.title}
        </span>
      ),
    },
    {
      name: "Product",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row?.category?.name}
        </span>
      ),
    },
    {
      name: "Promo Slug",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row?.slug}
        </span>
      ),
    },
    {
      name: "Nomor Urut",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row?.order}
        </span>
      ),
    },
    {
      name: "Is_homepage",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row?.isHomepage ? (
            <TickSquare size="14" color="#00DFBF" variant="Bold" />
          ) : (
            <CloseSquare size="14" color="#FE642F" variant="Bold" />
          )}
        </span>
      ),
    },
    {
      name: "Status Produk",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          <Badge color={row.status === 1 ? "success" : "danger"}>
            {row.status === 1 ? "Aktif" : "Nonaktif"}
          </Badge>
        </span>
      ),
    },
    {
      name: "Action",
      width: "120px",
      center: true,
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          <UncontrolledDropdown>
            <DropdownToggle tag="a">
              <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={() => toggle(row, "edit")}>
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  toggleConfirm({ ...row, status: row?.status ? 0 : 1 })
                }
              >
                {row?.status ? "Nonaktifkan" : "Aktifkan"}
              </DropdownItem>
              <DropdownItem>Detail</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </span>
      ),
    },
  ];
};

export default Columns;
