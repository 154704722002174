import {
  FETCH_TOPUP_SUCCESS,
  FETCH_TOPUP_FAILED,
  FETCH_TOPUP,
  UPDATE_STATUS_TOPUP,
  UPDATE_STATUS_TOPUP_SUCCESS,
  UPDATE_STATUS_TOPUP_FAILED,
  UPDATE_STATUS_CLEAN_UP,
  EXPORT_TOPUP_EXCEL,
  EXPORT_TOPUP_EXCEL_SUCCESS,
  EXPORT_TOPUP_EXCEL_FAILED,
  EXPORT_TOPUP_EXCEL_CLEAN_UP,
  FETCH_TOPUP_HISTORY,
  FETCH_TOPUP_HISTORY_SUCCESS,
  FETCH_TOPUP_HISTORY_FAILED,
} from "./actionTypes";

export const fetchTopup = (params) => ({
  type: FETCH_TOPUP,
  payload: { params },
});

export const fetchTopupSuccess = (data) => ({
  type: FETCH_TOPUP_SUCCESS,
  payload: data,
});

export const fetchTopupFailed = (error) => ({
  type: FETCH_TOPUP_FAILED,
  payload: error,
});

export const updateStatusTopup = (data, id) => ({
  type: UPDATE_STATUS_TOPUP,
  payload: { data, id },
});

export const updateStatusTopupSuccess = (data) => ({
  type: UPDATE_STATUS_TOPUP_SUCCESS,
  payload: data,
});

export const updateStatusTopupFailed = (error) => ({
  type: UPDATE_STATUS_TOPUP_FAILED,
  payload: error,
});

export const updateStatusTopupCleanUp = () => ({
  type: UPDATE_STATUS_CLEAN_UP,
});

export const exportTopup = (params) => ({
  type: EXPORT_TOPUP_EXCEL,
  payload: { params },
});

export const exportTopupSuccess = (data) => ({
  type: EXPORT_TOPUP_EXCEL_SUCCESS,
  payload: data,
});

export const exportTopupFailed = (error) => ({
  type: EXPORT_TOPUP_EXCEL_FAILED,
  payload: error,
});

export const exportTopupCleanUp = () => ({
  type: EXPORT_TOPUP_EXCEL_CLEAN_UP,
});

export const topupHistory = (params, id, config) => ({
  type: FETCH_TOPUP_HISTORY,
  payload: { params, id, config },
});

export const topupHistorySuccess = (data) => ({
  type: FETCH_TOPUP_HISTORY_SUCCESS,
  payload: data,
});

export const topupHistoryFailed = (error) => ({
  type: FETCH_TOPUP_HISTORY_FAILED,
  payload: error,
});
