import { EXPORT_TRANSACTION, FETCH_TRANSACTION, PUT_TRANSACTION } from "./actionTypes";

const {
  fetchTransaction,
  exportTransaction,
  putTransaction,
} = require("helpers/transaction_helper");
const { call, put, takeEvery } = require("redux-saga/effects");
const {
  fetchTransactionSuccess,
  fetchTransactionFailed,
  exportTransactionSuccess,
  exportTransactionFailed,
  putTransactionFailed,
  putTransactionSuccess,
} = require("./actions");

function* getTransactionData({ payload: { params } }) {
  try {
    const response = yield call(fetchTransaction, params);

    yield put(fetchTransactionSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchTransactionFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchTransactionFailed(error.response.data));
    }
  }
}

function* getExportData({ payload: { params } }) {
  try {
    const response = yield call(exportTransaction, params);

    yield put(exportTransactionSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(exportTransactionFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(exportTransactionFailed(error.response.data));
    }
  }
}

function* updateTransaction({payload: { data, id }}) {
  try {
  const response = yield call(putTransaction, id, data);

  yield put(putTransactionSuccess(response));
} catch (error) {
  if (error.response.status == 401) {
    localStorage.removeItem("authUser");
    localStorage.removeItem("user");

    location.href = "/unauthorized";
  } else if (error.response.status == 500) {
    yield put(putTransactionFailed(error.response.data));

    location.href = "/oops";
  } else {
    yield put(putTransactionFailed(error.response.data));
  }
}
}

function* TransactionSaga() {
  yield takeEvery(FETCH_TRANSACTION, getTransactionData);
  yield takeEvery(EXPORT_TRANSACTION, getExportData);
  yield takeEvery(PUT_TRANSACTION, updateTransaction);
}

export default TransactionSaga;
