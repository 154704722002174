import React from "react";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const { useSelector } = require("react-redux");
import PropTypes from "prop-types";

const columns = ({ onOpenEdit, onOpenUpdateStatus }) => {
  const { page, perPage } = useSelector((s) => s.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (_, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "Name",
      width: "180px",
      cell: ({ title }) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {title}
        </span>
      ),
    },
    {
      name: "slug",
      width: "180px",
      cell: ({ slug }) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {slug}
        </span>
      ),
    },
    {
      name: "Nomor Urut",
      width: "180px",
      cell: ({ order }) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {order}
        </span>
      ),
    },
    {
      name: "Status",
      width: "120px",
      cell: ({ status }) => (
        <span className="obj-el" data-tag="allowRowEvents">
          <Badge color={status ? "success" : "danger"}>
            <span className="obj-el">{status ? "Aktif" : "Tidak Aktif"}</span>
          </Badge>
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a">
            <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => onOpenEdit(row)}>Edit </DropdownItem>
            <DropdownItem onClick={() => onOpenUpdateStatus(row, row.id)}>
              {row.status === 1 ? "Nonaktifkan" : "Aktifkan"}
            </DropdownItem>
            <DropdownItem>Detail</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
};

columns.propTypes = {
  onOpenEditPrice: PropTypes.func,
  onOpenUpdateStatus: PropTypes.func,
};

export default columns;
