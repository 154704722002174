import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import {
  fetchDaftarHarga,
  handleChange,
  updateStatusHargaJual,
  updateStatusHargaJualCleanup,
  paramsCleanup,
  updateHargaJualCleanup,
} from "store/actions";
import Pagination from "../../components/Pagination";
import DataTable from "react-data-table-component";
import columns from "./columns";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from "./filter";
import ModalEdit from "./modalEdit";
import { toast, ToastContainer } from "react-toastify";
import SweetAlert from "components/Common/SweetAlert";
import CustomToast from "components/Common/CustomToast";

const dummyData = {
  slug: "telkomsel-5000",
  categoryId: 10,
  brandId: 42,
  typeId: 11,
  productName: "Telkomsel 5.000",
  sellerName: "PULSA GADGET",
  price: 5230,
  buyerSkuCode: "TSEL5",
  buyerProductStatus: 1,
  sellerProductStatus: 1,
  unlimitedStock: 1,
  stock: 0,
  multi: 1,
  startCutOff: "23:30:00",
  endCutOff: "00:30:00",
  desc: "Pulsa Telkomsel Rp 5.000",
  admin: null,
  commission: null,
  sellingPrice: 5305,
  status: 0,
  typePayment: "prepaid",
  createdAt: "2022-07-02T01:18:19.000Z",
  updatedAt: "2022-09-24T11:30:00.000Z",
  category_id: 10,
  brand_id: 42,
  type_id: 11,
  brand: {
    name: "TELKOMSEL",
  },
  priceFormatted: "5.230",
  sellingPriceFormatted: "5.305",
  statusString: "non aktif",
};

const alertTitle = {
  1: "Aktifkan Produk",
  0: "Nonaktifkan Produk",
};

const DaftarHarga = () => {
  document.title = "Daftar Harga | Pulsadong";

  const [openEditPrice, setOpenEditPrice] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState("");
  const [dataPrice, setDataPrice] = React.useState({
    id: null,
    buyerSkuCode: null,
    price: null,
    sellingPrice: null,
    status: 0,
  });
  const dispatch = useDispatch();

  const params = useSelector((s) => s.Params);
  const { data, loading, total } = useSelector((s) => s.DaftarHarga);
  const {
    success: successUpdateHargaJual,
    messageTitle: messageTitleUpdateHargaJual,
    message: messageUpdateHargaJual,
  } = useSelector((s) => s.UpdateHargaJual);
  const {
    loading: loadingUpdateStatus,
    message: messageUpdateStatus,
    messageTitle: messageTitleUpdateStatus,
    success: successUpdateStatus,
  } = useSelector((s) => s.UpdateStatusHargaJual);

  React.useEffect(() => {
    return () => {
      dispatch(paramsCleanup());
    };
  }, []);

  React.useEffect(() => {
    dispatch(fetchDaftarHarga(params));
  }, [params]);

  React.useEffect(() => {
    if (successUpdateStatus || successUpdateHargaJual) {
      toast(
        <CustomToast
          title={messageTitleUpdateStatus || messageTitleUpdateHargaJual}
          desc={messageUpdateStatus || messageUpdateHargaJual}
          type={
            successUpdateStatus || successUpdateHargaJual ? "success" : "error"
          }
        />
      );

      dispatch(updateStatusHargaJualCleanup());
      dispatch(updateHargaJualCleanup());
      dispatch(fetchDaftarHarga(params));
      setOpenAlert("");
      setOpenEditPrice(false);
    }
  }, [successUpdateStatus, successUpdateHargaJual]);

  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onOpenEditPrice = ({ id, buyerSkuCode, price, sellingPrice }) => {
    setDataPrice((prev) => ({
      ...prev,
      id,
      buyerSkuCode,
      price,
      sellingPrice,
    }));
    setOpenEditPrice(!openEditPrice);
  };

  const onCloseEditPrice = () => {
    setDataPrice({
      id: null,
      buyerSkuCode: null,
      price: null,
      sellingPrice: null,
      status: 0,
    });
    setOpenEditPrice(false);
  };

  const onOpenAlert = (status, id) => {
    setOpenAlert("update-status");

    setDataPrice((prev) => ({ ...prev, status, id }));
  };

  const onSubmitUpdateStatus = () => {
    dispatch(updateStatusHargaJual({ status: dataPrice.status }, dataPrice.id));
  };

  const onCancelUpdateStatus = () => {
    setDataPrice({
      id: null,
      buyerSkuCode: null,
      price: null,
      sellingPrice: null,
      status: 0,
    });

    setOpenAlert("");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Daftar Harga" />
          <Card>
            <CardBody className={loading ? "sekeleton active" : ""}>
              <Filter />
              <DataTable
                noHeader
                sortServer
                paginationServer
                columns={columns({
                  onOpenEditPrice,
                  onOpenUpdateStatus: onOpenAlert,
                })}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ModalEdit
        isOpen={openEditPrice}
        toggle={onCloseEditPrice}
        data={dataPrice}
      />
      <SweetAlert
        show={openAlert === "update-status"}
        title={alertTitle[dataPrice.status]}
        message="Apa kamu yakin?"
        onSubmit={onSubmitUpdateStatus}
        loading={loadingUpdateStatus}
        onCancel={onCancelUpdateStatus}
      />
      <ToastContainer position="bottom-right" progressClassName="progressbar" />
    </React.Fragment>
  );
};

export default DaftarHarga;
