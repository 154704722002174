import {
  ADD_KIOS,
  ADD_KIOS_CLEANUP,
  ADD_KIOS_FAILED,
  ADD_KIOS_SUCCESS,
  EDIT_KIOS,
  EDIT_KIOS_CLEANUP,
  EDIT_KIOS_FAILED,
  EDIT_KIOS_SUCCESS,
  FETCH_KIOS,
  FETCH_KIOS_FAILED,
  FETCH_KIOS_SUCCESS,
  HANDLE_CHANGE,
  HANDLE_CHANGE_CLEANUP,
  UPDATE_KIOS,
  UPDATE_KIOS_CLEANUP,
  UPDATE_KIOS_FAILED,
  UPDATE_KIOS_SUCCESS,
  UPDATE_STATUS_KIOS,
  UPDATE_STATUS_KIOS_CLEANUP,
  UPDATE_STATUS_KIOS_FAILED,
  UPDATE_STATUS_KIOS_SUCCESS,
  UPDATE_VERIFICATION,
  UPDATE_VERIFICATION_CLEANUP,
  UPDATE_VERIFICATION_FAILED,
  UPDATE_VERIFICATION_SUCCESS,
} from "./actionTypes";

export const fetchKios = (params) => ({
  type: FETCH_KIOS,
  payload: { params },
});

export const fetchKiosSuccess = (data) => ({
  type: FETCH_KIOS_SUCCESS,
  payload: data,
});

export const fetchKiosFailed = (error) => ({
  type: FETCH_KIOS_FAILED,
  payload: error,
});

export const handleChangeKios = (name, value) => ({
  type: HANDLE_CHANGE,
  payload: { name, value },
});

export const addKios = (data) => ({
  type: ADD_KIOS,
  payload: { data },
});

export const addKiosSuccess = (data) => ({
  type: ADD_KIOS_SUCCESS,
  payload: data,
});

export const addKiosFailed = (error) => ({
  type: ADD_KIOS_FAILED,
  payload: error,
});

export const addKiosCleanup = () => ({
  type: ADD_KIOS_CLEANUP,
});

export const handleChangeCleanup = () => ({
  type: HANDLE_CHANGE_CLEANUP,
});

export const updateStatusKios = (data, id) => ({
  type: UPDATE_STATUS_KIOS,
  payload: { data, id },
});

export const updateStatusKiosSuccess = (data) => ({
  type: UPDATE_STATUS_KIOS_SUCCESS,
  payload: data,
});

export const updateStatusKiosFailed = (error) => ({
  type: UPDATE_STATUS_KIOS_FAILED,
  payload: error,
});

export const updateStatusKiosCleanup = () => ({
  type: UPDATE_STATUS_KIOS_CLEANUP,
});

export const editKios = (id) => ({
  type: EDIT_KIOS,
  payload: { id },
});

export const editKiosSuccess = (data) => ({
  type: EDIT_KIOS_SUCCESS,
  payload: data,
});

export const editKiosFailed = (error) => ({
  type: EDIT_KIOS_FAILED,
  payload: error,
});

export const editKiosCleanup = () => ({
  type: EDIT_KIOS_CLEANUP,
});

export const updateKios = (data, id) => ({
  type: UPDATE_KIOS,
  payload: { data, id },
});

export const updateKiosSuccess = (data) => ({
  type: UPDATE_KIOS_SUCCESS,
  payload: data,
});

export const updateKiosFailed = (error) => ({
  type: UPDATE_KIOS_FAILED,
  payload: error,
});

export const updateKiosCleanup = () => ({
  type: UPDATE_KIOS_CLEANUP,
});

export const updateVerification = (data, id) => ({
  type: UPDATE_VERIFICATION,
  payload: { data, id },
});

export const updateVerificationSuccess = (data) => ({
  type: UPDATE_VERIFICATION_SUCCESS,
  payload: data,
});

export const updateVerificationFailed = (error) => ({
  type: UPDATE_VERIFICATION_FAILED,
  payload: error,
});

export const updateVerificationCleanup = () => ({
  type: UPDATE_VERIFICATION_CLEANUP,
});
