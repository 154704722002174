const { encodeQueryData } = require("helper");
const { get, put } = require("./api_helper");
const { FETCH_TRANSACTION, EXPORT_EXCEL_TRANSACTION } = require("./constant");

const fetchTransaction = (params) =>
  get(
    `${FETCH_TRANSACTION}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const exportTransaction = (params) =>
  get(
    `${EXPORT_EXCEL_TRANSACTION}${
      Object.keys(params).length > 0 ? `?${encodeQueryData(params)}` : ""
    }`
  );

const putTransaction = (id, body) =>
  put(`${FETCH_TRANSACTION}/${id}`, body)

export { fetchTransaction, exportTransaction, putTransaction };
