import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../store/actions";
import { useDispatch } from "react-redux";

const Logout = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logoutUser(props.history));
  }, [dispatch]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
