const {
  FETCH_TRANSACTION,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILED,
  EXPORT_TRANSACTION_CLEAN_UP,
  EXPORT_TRANSACTION,
  EXPORT_TRANSACTION_SUCCESS,
  EXPORT_TRANSACTION_FAILED,
  PUT_TRANSACTION,
  PUT_TRANSACTION_SUCCESS,
  PUT_TRANSACTION_FAILED,
  PUT_TRANSACTION_CLEAN_UP
} = require("./actionTypes");

const initState = {
  loading: false,
  data: [],
  total: 0,
  message: null,
};

const initStatePost = {
  loading: false,
  data: null,
  message: null,
  messageTitle: null,
};

const Transaction = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.total,
      };
    case FETCH_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        total: 0,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export const ExportExcel = (state = initStatePost, action) => {
  switch (action.type) {
    case EXPORT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: true,
      };
    case EXPORT_TRANSACTION_FAILED:
      return {
        ...state,
        data: null,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: false,
      };
    case EXPORT_TRANSACTION_CLEAN_UP:
      return initStatePost;
    default:
      return state;
  }
};

export const PutTransaction = (state = initStatePost, action) => {
  switch (action.type) {
    case PUT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case PUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: [],
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: true,
      };
    case PUT_TRANSACTION_FAILED:
      return {
        ...state,
        data: null,
        message: action.payload.message,
        messageTitle: action.payload.messageTitle,
        loading: false,
        success: false,
      };
    case PUT_TRANSACTION_CLEAN_UP:
      return initStatePost;
    default:
      return state;
  }
};

export default Transaction;
