export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILED = "FETCH_PRODUCT_LIST_FAILED";
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILED = "FETCH_CATEGORY_LIST_FAILED";
export const UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST";
export const UPDATE_PRODUCT_LIST_SUCCESS = "UPDATE_PRODUCT_LIST_SUCCESS";
export const UPDATE_PRODUCT_LIST_FAILED = "UPDATE_PRODUCT_LIST_FAILED";
export const UPDATE_PRODUCT_LIST_CLEAN = "UPDATE_PRODUCT_LIST_CLEAN";
export const UPDATE_STATUS_PRODUCT_LIST = "UPDATE_STATUS_PRODUCT_LIST";
export const UPDATE_STATUS_PRODUCT_LIST_SUCCESS =
  "UPDATE_STATUS_PRODUCT_LIST_SUCCESS";
export const UPDATE_STATUS_PRODUCT_LIST_FAILED =
  "UPDATE_STATUS_PRODUCT_LIST_FAILED";
export const UPDATE_STATUS_PRODUCT_LIST_CLEAN =
  "UPDATE_STATUS_PRODUCT_LIST_CLEAN";
