import { priceDecimal } from "helper";
import {
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILED,
  FETCH_TOP_SELLING,
  FETCH_TOP_SELLING_SUCCESS,
  FETCH_TOP_SELLING_FAILED,
  FETCH_TRANSACITON_ANALYTICS,
  FETCH_TRANSACITON_ANALYTICS_SUCCESS,
  FETCH_TRANSACITON_ANALYTICS_FAILED,
  FETCH_LATEST_TOPUP,
  FETCH_LATEST_TOPUP_SUCCESS,
  FETCH_LATEST_TOPUP_FAILED,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  data: [],
};

const INIT_STATE_TOP_SELLING = {
  loading: false,
  data: {
    totalOmset: {
      value: 0,
      percentage: 0,
    },
    topSellingProduct: [],
  },
};

const INIT_STATE_TRANSACTION_ANALYTICS = {
  loading: false,
  data: {
    labels: [],
    colors: [],
    series: [],
    totalTransaction: 0,
    transactionAnalytics: [],
  },
};

const INIT_STATE_LATEST_TOPUP = {
  loading: false,
  data: [],
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SUMMARY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUMMARY_SUCCESS:
      const { total, revenue, seller } = action.payload.data;

      const data = [
        {
          title: "Transaksi Jual",
          iconClass: "bx-copy-alt",
          value: priceDecimal(total.value, ","),
          percentage: total.percentage,
        },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          value: `Rp ${priceDecimal(revenue.value)}`,
          percentage: revenue.percentage,
        },
        {
          title: "Kios Pulsadong",
          iconClass: "bx-purchase-tag-alt",
          value: seller.value,
          percentage: seller.percentage,
        },
      ];

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_SUMMARY_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

export const TopSelling = (state = INIT_STATE_TOP_SELLING, action) => {
  switch (action.type) {
    case FETCH_TOP_SELLING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOP_SELLING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case FETCH_TOP_SELLING_FAILED:
      return {
        ...state,
        loading: false,
        data: INIT_STATE_TOP_SELLING,
      };
    default:
      return state;
  }
};

export const TransactionAnalytics = (
  state = INIT_STATE_TRANSACTION_ANALYTICS,
  action
) => {
  switch (action.type) {
    case FETCH_TRANSACITON_ANALYTICS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRANSACITON_ANALYTICS_SUCCESS:
      const labels = [],
        colors = [],
        series = [];
      action.payload.data.transactionAnalytics.map((item) => {
        labels.push(item.categoryName);
        series.push(item.totalTransaction);
        colors.push(item?.color ?? "#34C38F");
      });
      const data = {
        labels,
        colors,
        series,
        ...action.payload.data,
      };

      return {
        ...state,
        loading: false,
        data,
      };
    case FETCH_TRANSACITON_ANALYTICS_FAILED:
      return {
        ...state,
        loading: false,
        data: INIT_STATE_TRANSACTION_ANALYTICS,
      };
    default:
      return state;
  }
};

export const LatestTopup = (state = INIT_STATE_LATEST_TOPUP, action) => {
  switch (action.type) {
    case FETCH_LATEST_TOPUP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LATEST_TOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case FETCH_LATEST_TOPUP_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

export default Dashboard;
