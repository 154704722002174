import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  FETCH_LATEST_TOPUP,
  FETCH_SUMMARY,
  FETCH_TOP_SELLING,
  FETCH_TRANSACITON_ANALYTICS,
} from "./actionTypes";
import {
  fetchSummarySuccess,
  fetchSummaryFailed,
  fetchTopSellingSuccess,
  fetchTopSellingFailed,
  fetchTransactionAnalyticsSuccess,
  fetchTransactionAnalyticsFailed,
  fetchLatestTopupSuccess,
  fetchLatestTopupFailed,
} from "./actions";
import {
  fetchLatestTopup,
  fetchSummary,
  fetchTopSelling,
  fetchTransactionAnalytics,
} from "helpers/dashboard_helper";

function* getSummaryData() {
  try {
    const response = yield call(fetchSummary);

    yield put(fetchSummarySuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchSummaryFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchSummaryFailed(error.response.data));
    }
  }
}

function* getTopSelling() {
  try {
    const response = yield call(fetchTopSelling);

    yield put(fetchTopSellingSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchTopSellingFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchTopSellingFailed(error.response.data));
    }
  }
}

function* getTransactionAnalytics() {
  try {
    const response = yield call(fetchTransactionAnalytics);

    yield put(fetchTransactionAnalyticsSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchTransactionAnalyticsFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchTransactionAnalyticsFailed(error.response.data));
    }
  }
}

function* getLatestTopup({ payload: { params } }) {
  try {
    const response = yield call(fetchLatestTopup, params);

    yield put(fetchLatestTopupSuccess(response));
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("user");

      location.href = "/unauthorized";
    } else if (error.response.status == 500) {
      yield put(fetchLatestTopupFailed(error.response.data));

      location.href = "/oops";
    } else {
      yield put(fetchLatestTopupFailed(error.response.data));
    }
  }
}

function* dashboardSaga() {
  yield takeEvery(FETCH_SUMMARY, getSummaryData);
  yield takeEvery(FETCH_TOP_SELLING, getTopSelling);
  yield takeEvery(FETCH_TRANSACITON_ANALYTICS, getTransactionAnalytics);
  yield takeEvery(FETCH_LATEST_TOPUP, getLatestTopup);
}

export default dashboardSaga;
