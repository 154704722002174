import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import "../../assets/scss/custom/components/_sweetalert.scss";

const SweetAlert = ({
  title,
  message,
  show,
  onSubmit,
  onCancel,
  cancelLabel,
  submitLabel,
  type,
  loading,
}) => {
  let classIcon;

  switch (type) {
    case "success":
      classIcon = "mdi-check-circle-outline";
      break;
    case "failed":
      classIcon = "mdi-clone-circle-outline";
      break;
    default:
      classIcon = "mdi-alert-circle-outline";
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCancel}
      centered={true}
      className="sweet-alert"
    >
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className={`mdi ${classIcon}`} />
              <h2>{title}</h2>
              <p>{message}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-lg ms-2"
                onClick={onCancel}
              >
                {cancelLabel}
              </button>
              <button
                type="button"
                className="btn btn-warning btn-lg ms-2"
                onClick={onSubmit}
              >
                {loading ? (
                  <Row className="align-items-center">
                    <Col xs="auto">Loading</Col>
                    <Col xs="auto">
                      <Spinner size="sm" />
                    </Col>
                  </Row>
                ) : (
                  submitLabel
                )}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const types = {
  confirm: "confirm",
  success: "success",
  failed: "failed",
};

SweetAlert.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(types)),
  loading: PropTypes.bool,
};

SweetAlert.defaultProps = {
  title: "Are you sure?",
  message: "You won't be able to revert this!",
  cancelLabel: "Cancel",
  submitLabel: "Yes",
  loading: false,
};

export default SweetAlert;
