import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Error401 from "../pages/Error401";
import Error500 from "../pages/Error500";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import TopUp from "../pages/TopUp/index";
import Transaction from "pages/Transaction";
import Kios from "pages/Kios";
import FormKios from "pages/FormKios";
import DaftarHarga from "pages/DaftarHarga";
import DaftarProduk from "pages/DaftarProduk";
import Promo from "pages/Promo";
import Academy from "pages/Academy";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/top-up", component: TopUp },
  { path: "/transaction", component: Transaction },
  { path: "/kios", component: Kios },
  { path: "/kios/add", component: FormKios },
  { path: "/kios/edit/:id", component: FormKios },
  { path: "/daftar-harga", component: DaftarHarga },
  { path: "/daftar-produk", component: DaftarProduk },
  { path: "/promo", component: Promo },
  { path: "/akademi", component: Academy },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/unauthorized", component: Error401 },
  { path: "/oops", component: Error500 },
];

export { publicRoutes, authProtectedRoutes };
