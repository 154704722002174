import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionAnalytics } from "store/actions";

const options = {
  legend: { show: !1 },
  plotOptions: {
    pie: {
      donut: {
        size: "70%",
      },
    },
  },
};

const TransactionAnalytics = (props) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.TransactionAnalytics);
  const { labels, colors, series } = data;

  React.useEffect(() => {
    dispatch(fetchTransactionAnalytics());
  }, []);

  return (
    <React.Fragment>
      <Col xs={12} md={4}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Transaksi Analytics</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={{ ...options, labels, colors }}
                  series={series}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                {data.transactionAnalytics.map((item, key) => (
                  <Col xs="4" key={key}>
                    <div className="mt-4">
                      <p className="mb-2 text-truncate">
                        <i
                          className="mdi mdi-circle me-1"
                          style={{ color: item.color }}
                        />{" "}
                        {item.categoryName}
                      </p>
                      <h5>{item.totalTransaction}</h5>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TransactionAnalytics;
