import SweetAlert from "components/Common/SweetAlert";
import { priceDecimal } from "helper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { topupHistory } from "store/actions";
import CardHistory from "./cardHistory";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";

const ModalDetail = ({ isOpen = false, onClosed = () => {}, data = null }) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const dispatch = useDispatch();
  const history = useSelector((s) => s.HistoryTopup);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (isOpen) {
      dispatch(topupHistory({ page: 1, perPage: 10 }, data?.id, { signal }));
    }

    return () => {
      controller.abort();
    };
  }, [isOpen]);

  const close = () => {
    onClosed();
  };

  const onCancelTopup = () => setShowConfirm(false);

  const onSubmitTopup = () => {
    setShowConfirm(false);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} size="lg" toggle={close} id="modal-detail-topup">
        <ModalHeader toggle={close}>Riwayat Topup Saldo</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" md="6">
              {history.data.map((item) => {
                return (
                  <CardHistory
                    kiosName={`${item.seller.firstName} ${item.seller.lastName}`}
                    amount={item.amount}
                    createdAt={item.createdAt}
                    status={item.status}
                    key={item.id}
                  />
                );
              })}
            </Col>
            <Col xs="12" md="6">
              <Row>
                <Col xs="auto">
                  {data?.profilePicture === null ? (
                    <img
                      src={data?.profilePicture}
                      alt={`${data?.firstName} ${data?.lastName}`}
                      width={80}
                      height={80}
                      className="rounded"
                    />
                  ) : (
                    <InitialsAvatar
                      name={`${data?.firstName} ${data?.lastName}`}
                    />
                  )}
                </Col>
                <Col>
                  <h3>
                    {data?.firstName} {data?.lastName}
                  </h3>
                  <p>
                    {data?.city} <Badge color="info">Mitra</Badge>
                  </p>
                  <Row>
                    <Col>
                      <p>Sisa Saldo</p>
                    </Col>
                    <Col xs="auto">:</Col>
                    <Col>Rp. {priceDecimal(data?.currentBalance)}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button outline color="danger" onClick={close}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
      <SweetAlert
        title="Apa kamu yakin?"
        message="Pastikan nominal saldo sudah sesuai dengan permintaan kios"
        type="confirm"
        show={showConfirm}
        onCancel={onCancelTopup}
        onSubmit={onSubmitTopup}
      />
    </React.Fragment>
  );
};

export default React.memo(ModalDetail);
