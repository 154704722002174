import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Card, CardBody, Nav, NavItem, NavLink, Table } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { fetchLatestTopup } from "store/actions";
import { priceDecimal } from "helper";

const STATUS = {
  0: "Pembayaran Pending",
  1: "Pembayaran Gagal",
  2: "Pembayaran Berhasil",
};

const LatestTopup = (props) => {
  const [activeTab, setActiveTab] = useState("0");
  const dispatch = useDispatch();
  const { data, loading } = useSelector((s) => s.LatestTopup);

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  useEffect(() => {
    dispatch(fetchLatestTopup({ status: Number(activeTab) }));
  }, [activeTab]);

  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Topup Saldo Terbaru</h4>

            <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "0",
                  })}
                  onClick={() => {
                    toggleTab("0");
                  }}
                >
                  Request
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Completed
                </NavLink>
              </NavItem>
            </Nav>

            <div className="mt-4">
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      data.map((item, key) => (
                        <tr key={item.id}>
                          <td style={{ width: "50px" }}>{key + 1}</td>
                          <td>
                            <h5 className="text-truncate font-size-14 mb-1">
                              {item.seller?.firstName} {item.seller?.lastName}
                            </h5>
                            <p className="text-muted mb-0">
                              {STATUS[item.status]}
                            </p>
                          </td>
                          <td style={{ width: "90px" }}>
                            Rp. {priceDecimal(item.amount)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>

          <div className="card-footer bg-transparent border-top">
            <div className="text-center">
              <Link to="/top-up" className="btn btn-primary ">
                {" "}
                More Request
              </Link>
            </div>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default memo(LatestTopup);
