import { put, take } from "redux-saga/effects";
import { handleChange } from "./actions";
import { HANDLE_CHANGE } from "./actionTypes";

function* onChange({ payload: { name, value } }) {
  yield put(handleChange(name, value));
}

function* ParamsSaga() {
  yield take(HANDLE_CHANGE, onChange);
}

export default ParamsSaga;
