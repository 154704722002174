import Filter from "pages/TopUp/filter";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import {
  exportTransaction,
  exportTransactionCleanup,
  fetchTransaction,
  handleChange,
  paramsCleanup,
  putTransaction,
  putTransactionCleanup,
} from "store/actions";
import Pagination from "../../components/Pagination";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import columns from "./columns";
import { ToastContainer, toast } from "react-toastify";
import CustomToast from "components/Common/CustomToast";
import SweetAlert from "components/Common/SweetAlert";

const dummyData = {
  orderId: "PLNPDXAL",
  sellerId: 1,
  customerNo: "530000000001",
  customerName: null,
  productId: 141,
  capitalPrice: null,
  admin: 0,
  sellingPrice: 2750,
  status: 2,
  createdAt: "2022-07-27T05:20:19.000Z",
  updatedAt: "2022-07-28T17:14:52.000Z",
  completedAt: "2022-07-28T17:14:52.000Z",
  logs: null,
  additionalInfo: null,
  serialNumber: null,
  seller_id: 1,
  product_id: 141,
  seller: null,
  statusString: "non aktif",
  profit: 2750,
};

const Transaction = () => {
  document.title = "Transaction | Pulsadong";
  const dispatch = useDispatch();
  const { data, loading, message, total } = useSelector((s) => s.Transaction);
  const params = useSelector((state) => state.Params);
  const {
    data: dataExport,
    loading: loadingExport,
    message: messageExport,
    messageTitle: messageTitleExport,
    success: successExport,
  } = useSelector((state) => state.ExportExcelTransaction);
  const {
    data: dataPut,
    loading: loadingPut,
    message: messagePut,
    messageTitle: messageTitlePut,
    success: successPut,
  } = useSelector((state) => state.PutTransaction);
  const downloadRef = React.useRef();
  const [showConfirm, setShowConfirm] = useState(false)
  const [payloadConfirm, setPayloadConfirm] = useState(null)

  React.useEffect(() => {
    return () => {
      dispatch(paramsCleanup());
    };
  }, []);

  React.useEffect(() => {
    dispatch(fetchTransaction(params));
  }, [params]);

  React.useEffect(() => {
    if (dataExport) {
      downloadRef.current.src = dataExport?.url;

      toast(
        <CustomToast
          title={messageTitleExport}
          type={successExport ? "success" : "error"}
          desc={messageExport}
        />
      );
      dispatch(exportTransactionCleanup());
    }
  }, [dataExport]);

  React.useEffect(() => {
    if (dataPut) {
      toast(
        <CustomToast
          title={messageTitlePut}
          type="success"
          desc={messagePut}
        />
      );
      dispatch(putTransactionCleanup());
      onCancelConfirm()
      dispatch(fetchTransaction(params));
    }
  }, [dataPut]);
  
  const onPageChange = (page) => {
    dispatch(handleChange("page", page.selected + 1));
  };

  const onExportExcel = (type) => {
    const newParams = {
      ...params,
      type,
    };

    dispatch(exportTransaction(newParams));
  };

  const onCancelConfirm = () => {
    setPayloadConfirm(null)
    setShowConfirm(false)
  }

  const onShowConfirm = (id, status) => {
    setPayloadConfirm({id, status})
    setShowConfirm(true)
  }

  const onSubmitConfirm = () => {
    const formData = {
      status: payloadConfirm?.status
    }
    delete formData.id

    dispatch(putTransaction(formData, payloadConfirm.id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pulsadong" breadcrumbItem="Transaksi Jual" />
          <Card>
            <CardBody>
              <Filter
                searchKey="search"
                onExport={onExportExcel}
                loadingExport={loadingExport}
              />
              <DataTable
                columns={columns({onShowConfirm})}
                data={
                  loading ? Array.from({ length: 5 }, () => dummyData) : data
                }
                noHeader
                sortServer
                paginationServer
              />
              {total > 0 && (
                <Pagination
                  totalRow={total}
                  perPage={params.perPage}
                  pageCount={Math.ceil(total / params.perPage)}
                  forcePage={params.page - 1}
                  onPageChange={onPageChange}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer position="bottom-right" progressClassName="progressbar" />
      <iframe ref={downloadRef} />
      <SweetAlert
        title="Apa kamu yakin?"
        message="Transaksi ini akan dikonfirmasi dan tidak dapat dikembalikan lagi"
        type="confirm"
        show={showConfirm}
        onCancel={onCancelConfirm}
        onSubmit={onSubmitConfirm}
      />
    </React.Fragment>
  );
};

export default Transaction;
