import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const FormInput = ({
  label = "",
  children,
  getValue = () => {},
  name = "",
  value = "",
  message = null,
  ...inputArgs
}) => {
  const [val, setVal] = React.useState(value || "");

  return (
    <FormGroup>
      <Label>{label}</Label>
      <input type="hidden" name={name} value={val} />
      {inputArgs?.type === "select" ? (
        <Input
          {...inputArgs}
          value={val}
          onChange={(e) => setVal(e.target.value)}
        >
          {children}
        </Input>
      ) : (
        <Input
          {...inputArgs}
          value={val}
          onChange={(e) => setVal(e.target.value)}
        />
      )}
      {message ? <small className="text-danger">{message}</small> : null}
    </FormGroup>
  );
};

export default React.memo(FormInput);
