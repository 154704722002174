import moment from "moment";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import PropTypes from "prop-types";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";

const columns = ({ onUpdateStatus, onEditData, onUpdateVerification }) => {
  const { page, perPage } = useSelector((s) => s.Params);

  return [
    {
      name: "#",
      width: "80px",
      cell: (_, index) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {page * perPage - perPage + (index + 1)}
        </span>
      ),
    },
    {
      name: "Nama Lengkap",
      width: "200px",
      cell: (row) => (
        <Row className="align-items-center">
          <Col xs="auto">
            {row.profilePicture ? (
              <img
                src={row.profilePicture}
                className="rounded-circle obj-el image-avatar"
                data-tag="allowRowEvents"
              />
            ) : (
              <InitialsAvatar name={`${row.firstName} ${row.lastName}`} />
            )}
          </Col>
          <Col>
            <span className="obj-el" data-tag="allowRowEvents">
              {row.firstName} {row.lastName}
            </span>
          </Col>
        </Row>
      ),
    },
    {
      name: "Nomor Telepon",
      width: "150px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          +62{row.phone}
        </span>
      ),
    },
    {
      name: "Email",
      width: "220px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.email}
        </span>
      ),
    },
    {
      name: "Kota",
      width: "220px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.regency.name}
        </span>
      ),
    },
    {
      name: "Status",
      width: "120px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          <Badge color={row.status === 1 ? "success" : "danger"}>
            {row.status === 1 ? "Aktif" : "Nonaktif"}
          </Badge>
        </span>
      ),
    },
    {
      name: "Register Date",
      width: "200px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {moment(row.createdAt).format("DD MMM Y, HH:mm")}
        </span>
      ),
    },
    {
      name: "Verification Status",
      width: "200px",
      cell: (row) => (
        <span className="obj-el" data-tag="allowRowEvents">
          {row.verificationStatus ?? "-"}
        </span>
      ),
    },
    {
      name: "Action",
      sortable: false,
      minWidth: 43,
      cell: (row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle tag="a">
              <i className="bx bx-dots-horizontal-rounded fs-2 text-dark" />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Detail Kios</DropdownItem>
              <DropdownItem onClick={() => onEditData(row.id)}>
                Edit Profil
              </DropdownItem>
              <DropdownItem
                onClick={() => onUpdateStatus(row.id, row.status === 1 ? 0 : 1)}
              >
                {row.status === 1 ? "Nonaktifkan" : "Aktifkan"}
              </DropdownItem>
              {row.verificationStatusCode === "VPRS" ? (
                <>
                  <DropdownItem
                    onClick={() => onUpdateVerification("VAPR", row.id)}
                  >
                    Setujui Verifikasi
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onUpdateVerification("VRJC", row.id)}
                  >
                    Tolak Verifikasi
                  </DropdownItem>
                </>
              ) : null}
              {row.verificationStatusCode === "PPRS" ||
              row.verificationStatusCode === "VAPR" ? (
                <>
                  <DropdownItem
                    onClick={() => onUpdateVerification("PSCS", row.id)}
                  >
                    Aktifkan Pembayaran
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onUpdateVerification("PFLD", row.id)}
                  >
                    Nonaktifkan Pembayaran
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
};

columns.propTypes = {
  onUpdateStatus: PropTypes.func,
  onEditData: PropTypes.func,
};

export default columns;
