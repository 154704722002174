import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;
const BEARER_TOKEN = "Bearer " + localStorage.getItem("authUser");

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: BEARER_TOKEN,
  },
});

axiosApi.defaults.headers.common["Authorization"] = BEARER_TOKEN;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function postForm(url, data, config = {}) {
  return axiosApi.postForm(url, data, config).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}
