import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { uploadImage } from "store/actions";

const useUpload = () => {
  const dispatch = useDispatch();
  const { banner } = useSelector((s) => s.Upload);
  const [tempFile, setTempFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => setUrl(banner), [banner]);

  const doUpload = (formData, config, name) =>
    dispatch(uploadImage(formData, config, name));

  const saveFileTemp = (file) => {
    setTempFile(file);
  };

  return { url, doUpload, tempFile, saveFileTemp };
};

export default useUpload;
