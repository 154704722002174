import {
  FETCH_CATEGORY_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LIST_FAILED,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_CATEGORY_LIST_SUCCESS,
  FETCH_CATEGORY_LIST_FAILED,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCT_LIST_FAILED,
  UPDATE_PRODUCT_LIST_CLEAN,
  UPDATE_STATUS_PRODUCT_LIST,
  UPDATE_STATUS_PRODUCT_LIST_SUCCESS,
  UPDATE_STATUS_PRODUCT_LIST_FAILED,
  UPDATE_STATUS_PRODUCT_LIST_CLEAN,
} from "./actionTypes";

export const fetchProductList = (params) => ({
  type: FETCH_PRODUCT_LIST,
  payload: { params },
});

export const fetchProductListSuccess = (data) => ({
  type: FETCH_PRODUCT_LIST_SUCCESS,
  payload: data,
});

export const fetchProductListFailed = (error) => ({
  type: FETCH_PRODUCT_LIST_FAILED,
  payload: error,
});

export const fetchCategoryList = () => ({
  type: FETCH_CATEGORY_LIST,
});

export const fetchCategoryListSuccess = (data) => ({
  type: FETCH_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const fetchCategoryListFailed = (error) => ({
  type: FETCH_CATEGORY_LIST_FAILED,
  payload: error,
});

export const updateProductList = (data, id) => ({
  type: UPDATE_PRODUCT_LIST,
  payload: { data, id },
});

export const updateProductListSuccess = (data) => ({
  type: UPDATE_PRODUCT_LIST_SUCCESS,
  payload: data,
});

export const updateProductListFailed = (error) => ({
  type: UPDATE_PRODUCT_LIST_FAILED,
  payload: error,
});

export const updateProductListClean = () => ({
  type: UPDATE_PRODUCT_LIST_CLEAN,
});

export const updateStatusProductList = (data, id) => ({
  type: UPDATE_STATUS_PRODUCT_LIST,
  payload: { data, id },
});

export const updateStatusProductListSuccess = (data) => ({
  type: UPDATE_STATUS_PRODUCT_LIST_SUCCESS,
  payload: data,
});

export const updateStatusProductListFailed = (error) => ({
  type: UPDATE_STATUS_PRODUCT_LIST_FAILED,
  payload: error,
});

export const updateStatusProductListClean = () => ({
  type: UPDATE_STATUS_PRODUCT_LIST_CLEAN,
});
