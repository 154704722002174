import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "components/Common/SweetAlert";
import { updateProductList } from "store/actions";
import { uploadImage } from "helpers/master_helper";

const ModalEdit = ({
  categories,
  isOpen,
  message,
  messageTitle,
  success,
  formData,
  onClose,
}) => {
  const defaultValue = useMemo(
    () => ({
      sectionId: 0,
      name: "",
      status: 0,
      icon: "",
      orderNo: 0,
      id: 0,
    }),
    [isOpen]
  );
  const [data, setData] = useState(defaultValue);
  const [showConfirm, setShowConfirm] = useState(false);
  const [preview, setPreview] = useState(null);
  const [newIcon, setNewIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { loading: loadingUpdate, message: messageUpdate } = useSelector(
    (s) => s.UpdateProductList
  );

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      sectionId: formData.sectionId,
      name: formData.name,
      status: formData.status,
      icon: formData.icon,
      orderNo: formData.orderNo,
      id: formData.id,
    }));

    setPreview(formData.icon);
  }, [formData]);

  useEffect(() => {
    if (messageUpdate != "") {
      onCloseConfirm();
      onCloseModal();
      setPreview(null);
      setNewIcon(null);
    }
  }, [messageUpdate]);

  const onChangeData = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onCloseModal = () => {
    onClose();

    setData(defaultValue);
  };

  const onShowConfirm = (e) => {
    e.preventDefault();

    setShowConfirm(true);
  };

  const onCloseConfirm = () => {
    setShowConfirm(false);
  };

  const onSubmit = () => {
    let icon = data.icon;

    if (newIcon) {
      setLoading(true);

      const imageData = new FormData();

      imageData.append("image", newIcon);

      uploadImage(imageData)
        .then((res) => {
          dispatch(updateProductList({ ...data, icon: res.data.url }, data.id));

          delete data.id;

          setData((prev) => ({ ...prev, ...data }));
          setLoading(false);
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
        });
    } else {
      dispatch(updateProductList({ ...data, icon }, data.id));

      delete data.id;

      setData((prev) => ({ ...prev, ...data }));
    }
  };

  const onChangeFile = (e) => {
    const [file] = e.target.files;
    const url = URL.createObjectURL(file);

    setNewIcon(file);
    setPreview(url);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onCloseModal}>Edit Produk</ModalHeader>
        <ModalBody>
          {!success ? (
            <Alert color="danger">
              {messageTitle} - {message}
            </Alert>
          ) : null}
          <Form method="post" onSubmit={onShowConfirm}>
            <FormGroup>
              <Label>Kategori Produk</Label>
              <Input
                type="select"
                name="sectionId"
                value={data.sectionId}
                onChange={onChangeData}
              >
                <option>Kategori Produk</option>
                {categories.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Nama Produk</Label>
              <Input
                type="text"
                name="name"
                value={data.name}
                onChange={onChangeData}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                name="status"
                value={data.status}
                onChange={onChangeData}
              >
                <option>Status</option>
                <option value="1">Aktif</option>
                <option value="0">Non Aktif</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Icon</Label>
              <Input type="file" accept=".svg" onChange={onChangeFile} />
              {preview ? (
                <img src={preview} className="mt-3 img-thumbnail" />
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>No Urut</Label>
              <Input
                type="number"
                name="orderNo"
                value={data.orderNo}
                onChange={onChangeData}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={onCloseModal}>
            Batal
          </Button>
          <Button color="warning" onClick={onShowConfirm}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
      <SweetAlert
        title="Apa kamu yakin?"
        message="Data yang diubah akan disimpan"
        loading={loading || loadingUpdate}
        submitLabel="Ya"
        cancelLabel="Tidak"
        show={showConfirm}
        onSubmit={onSubmit}
        onCancel={onCloseConfirm}
      />
    </React.Fragment>
  );
};

ModalEdit.propTypes = {
  categories: PropTypes.array,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  messageTitle: PropTypes.string,
  success: PropTypes.bool,
  formData: PropTypes.object,
  onClose: PropTypes.func,
};

export default ModalEdit;
