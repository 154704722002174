import {
  FETCH_PROMO,
  FETCH_PROMO_FAILED,
  FETCH_PROMO_SUCCESS,
  PROMO_HANDLE_CHANGE,
  PAYLOAD_CLEANUP,
  UPDATE_PROMO,
  UPDATE_PROMO_FAILED,
  UPDATE_PROMO_SUCCESS,
  DELETE_PAYLOAD_CHANGE,
  UPDATE_PROMO_CLEANUP,
  POST_PROMO,
  POST_PROMO_SUCCESS,
  POST_PROMO_FAILED,
} from "./actionTypes";

export const payloadChange = (name, value) => ({
  type: PROMO_HANDLE_CHANGE,
  payload: { name, value },
});

export const deletePayloadChange = (name) => ({
  type: DELETE_PAYLOAD_CHANGE,
  payload: { name },
});

export const payloadFormCleanup = () => ({
  type: PAYLOAD_CLEANUP,
});

export const fetchPromo = (params) => ({
  type: FETCH_PROMO,
  payload: { params },
});

export const fetchPromoSuccess = (data) => ({
  type: FETCH_PROMO_SUCCESS,
  payload: data,
});

export const fetchPromoFailed = (error) => ({
  type: FETCH_PROMO_FAILED,
  payload: error,
});

export const updatePromo = (id, data) => ({
  type: UPDATE_PROMO,
  payload: { id, data },
});

export const updatePromoSuccess = (data) => ({
  type: UPDATE_PROMO_SUCCESS,
  payload: data,
});

export const updatePromoFailed = (error) => ({
  type: UPDATE_PROMO_FAILED,
  payload: error,
});

export const updatePromoCleanup = () => ({
  type: UPDATE_PROMO_CLEANUP,
});

export const postPromo = (data) => ({
  type: POST_PROMO,
  payload: { data },
});

export const postPromoSuccess = (data) => ({
  type: POST_PROMO_SUCCESS,
  payload: data,
});

export const postPromoFailed = (error) => ({
  type: POST_PROMO_FAILED,
  payload: error,
});
