import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";

//Dashboard
import Dashboard, {
  TopSelling,
  TransactionAnalytics,
  LatestTopup,
} from "./dashboard/reducer";

//Topup
import Topup, {
  UpdateStatusTopup,
  ExportExcel,
  HistoryTopup,
} from "./topup/reducer";

//Params
import Params from "./params/reducer";

//Kios
import Kios, {
  FormKios,
  AddKios,
  UpdateStatusKios,
  EditKios,
  UpdateKios,
  UpdateVerification,
} from "./kios/reducer";

//Master
import Bank, {
  Provinces,
  Regencies,
  Districts,
  Upload,
  Params as MasterParams,
} from "./master/reducer";

//DaftarHarga
import DaftarHarga, {
  UpdateHargaJual,
  UpdateStatusHargaJual,
} from "./daftarHarga/reducer";

//Transaction
import Transaction, {
  ExportExcel as ExportExcelTransaction,
  PutTransaction
} from "./transaction/reducer";

//Balance
import Balance from "./balance/reducer";

//Daftar produk
import DaftarProduk, {
  CategoryList,
  UpdateProductList,
  UpdateStatusProductList,
} from "./daftarProduk/reducer";

import Promo, { GetPromo, UpdatePromo, PostPromo } from "./promo/reducer";

//Academy Banner
import AcademyBanner from "./academy/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  Topup,
  UpdateStatusTopup,
  Params,
  ExportExcel,
  HistoryTopup,
  Kios,
  Bank,
  Provinces,
  Regencies,
  Districts,
  Upload,
  FormKios,
  AddKios,
  EditKios,
  UpdateStatusKios,
  DaftarHarga,
  UpdateHargaJual,
  UpdateStatusHargaJual,
  UpdateKios,
  Transaction,
  ExportExcelTransaction,
  Balance,
  TopSelling,
  TransactionAnalytics,
  LatestTopup,
  DaftarProduk,
  CategoryList,
  UpdateProductList,
  UpdateStatusProductList,
  Promo,
  MasterParams,
  GetPromo,
  UpdatePromo,
  PostPromo,
  UpdateVerification,
  AcademyBanner,
  PutTransaction
});

export default rootReducer;
